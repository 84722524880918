import React from "react";
import {
    DOMEN_SERVER, MANEGMENT_APLICATIONS, TASK_APP_ARTICLES, TASK_ARTICLES, TASK_CREATE_ARTICLE,
    TASK_CREATE_BOX, TASK_CREATE_OPTION, TASK_DELETE_BOX, TASK_DELETE_OPTION, TASK_DETAIL_BOX, TASK_OPERATIONS_BOX,
    TASK_ORG_LIST, TASK_SUB_LIST, TASK_UPDATE_ARTICLE, TASK_WB_IMPORT_ARTICLES, TASK_SUBJECTS_COUNTRY, TASK_ARTICLES_ALL_REQUEST, TASK_DELETE_ARTICLE, MANEGMENT_PRINT_SHK_BOX
} from "../Config/Base";
import axios from "axios";
import Select from "react-select";
import { Button, Modal, Row, Table, Col, Container, Card, Form, Carousel } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import XLSX from "sheetjs-style";
import { Spiner } from "./Accounting";
import { Box } from "@mantine/core";
import { DataGrid, GridToolbarContainer, GridToolbarExport, ruRU, GridRowModes, GridActionsCellItem, } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import JsBarcode from "jsbarcode";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { SessionEndend } from "./Auth";
import BoxImg from "../images/box.png"



//Заявки
export class ActiveApplications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', modalAdd: false, modalTitle: '', box: '', spiner: 'hidden', articles: [], modal: '', spiner2: '',
            article_id: '', num: '', operations: [], application_id: 0, opp: '', info_operations: [], box_data: [], operations_box: [],
            app: [], aplications: [], paginate: [], paginate: [], id: '', box_id: 0, box_q: 1, box_oper_id: [], razb: 0, index: 0, box_count: 0,
        };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    modalAddClose = () => this.setState(val => ({ modalAdd: false, box: '', box_id: 0, num: '' }));
    modalAddCShow = () => this.setState(val => ({ modalAdd: true }), (() => this.operations()));
    applications = async (page) => {
        const arr = [1];
        this.setState({ spiner: '' });
        const res = await axios.post(DOMEN_SERVER + MANEGMENT_APLICATIONS, { status_id: arr, page: page.page }).then(res => {
            const current_page = Number(res.data.cur_page);
            const last_page = Number(res.data.last_page);
            this.state.paginate.length = 0;
            if (this.state.paginate.length === 0) {
                this.state.paginate.push(<Button key={current_page * 20} variant="outline-dark" className="ms-1" size="sm" onClick={() => this.applications({ page: 1 })} >&laquo;</Button>);
                if ((current_page - 2) > 0) { this.state.paginate.push(<Button key={current_page - 2} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page - 2 })} >{current_page - 2}</Button>,) }
                if ((current_page - 1) > 0) { this.state.paginate.push(<Button key={current_page - 1} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page - 1 })} >{current_page - 1}</Button>,) }
                this.state.paginate.push(<Button key={current_page} disabled size="sm" className="ms-1" variant="light">{current_page}</Button>,);
                if ((current_page + 1) <= last_page) { this.state.paginate.push(<Button key={current_page + 1} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page + 1 })}  >{current_page + 1}</Button>,) }
                if ((current_page + 2) <= last_page) { this.state.paginate.push(<Button key={current_page + 2} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page + 2 })} >{current_page + 2}</Button>,) }
                this.state.paginate.push(<Button key={current_page * 10} variant="outline-dark" className="ms-1" size="sm" onClick={() => this.applications({ page: last_page })} >&raquo;</Button>);
            }

            this.setState({ app: res.data.data });
            this.setState({ spiner: 'hidden' });
        });
    }
    accardion = () => {
        this.state.aplications.length = 0;
        this.state.aplications.push(
            this.state.app.map((a, i) => (
                <Accordion.Item key={i} eventKey={i} >
                    <Accordion.Header onClick={() => this.primer(a.id)}><small className="text-dark">№:{a.id + "|"}{a.organization + ">"}{a.subject}</small></Accordion.Header>
                    <Accordion.Body>
                        <Container>
                            <Row>
                                <Col className="d-flex justify-content-end col-12">
                                    <small className="m-1"><i>Создал: {a.name + ' ' + a.created_at}</i></small>
                                    <small className="m-1"><i>Обновил: {a.manager + ' ' + a.updated_at}</i></small>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end col-2">
                                    <b><small>Описание:</small></b>
                                </Col>
                                <Col className="d-flex justify-content-start col-10">
                                    <small>{a.description}</small>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end col-2">
                                    <small><b>Операции:</b></small>
                                </Col>
                                <Col className="d-flex justify-content-start col-9">
                                    <small>{a.razbivka === 0 ? a.services : a.services}</small>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="col-11">
                                    <Row>
                                        <Col className="d-flex justify-content-start text-primary"><small><b>Выполнено: короба {this.state.info_operations.box_all > 0 ? this.state.info_operations.box_all : 0}, товар {this.state.info_operations.num_all > 0 ? this.state.info_operations.num_all : 0}</b></small>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="d-flex justify-content-start text-info"><small><b>Ваш вклад: короба {this.state.info_operations.box_me > 0 ? this.state.info_operations.box_me : 0}, товар {this.state.info_operations.num_me > 0 ? this.state.info_operations.num_me : 0}</b></small>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="d-flex justify-content-start text-success"><small> <b>Ваш бонус: короба: {this.state.info_operations.box_sum_rate > 0 ? this.state.info_operations.box_sum_rate : 0}, товар: {this.state.info_operations.sub_sum_rate > 0 ? this.state.info_operations.sub_sum_rate : 0} Итого: {this.state.info_operations.sum_rate_all}</b></small>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className=" justify-content-start ">
                                    <div>{a.razbivka === 1 ? <div className="d-flex m-1"><input className="ms-2 me-2" name="box_q" value={this.state.box_q} placeholder="Кол" type="number" onChange={this.inputChange} />
                                        <Button size="sm" variant="primary" onClick={() => this.newBox(a.id)}>&#10010;Короб</Button></div> : <Button size="sm" variant="warning" onClick={() => this.addPosition({ razbivka: a.razbivka, application_id: a.id, new: 1, box_id: 0, box_name: '', user: '' })}>&#10010;Артикулы</Button>}</div>
                                </Col>
                            </Row>
                            <Container size="sm" className="mt-3 ">

                                {this.state.box_count === 0 ? <p><b>Нет коробов</b></p> : this.state.box_data.map((val, e) => (
                                    <div key={"box" + e} className="mt-2 mb-3 border "><div className="d-flex HeadBG">
                                        <div className="w-100 d-flex  justify-content-start"><small ><b>{e + 1 + ')'}Короб: <i><u>{val.name}</u></i> || </b> {val.user}</small></div>
                                        <div className=" w-100 d-flex  justify-content-end "><Button size="sm" variant="danger" onClick={() => this.delete_box({ box_id: val.id, name: val.name })}>&#10006;</Button></div>
                                    </div><Row><Col className="d-flex justify-content-center">
                                        <Button className="ms-2 mb-2 mt-1" variant="warning" onClick={() => this.addPosition({ razbivka: a.razbivka, application_id: a.id, new: 0, box_id: Number(val.id), box_name: val.name, user: val.user })}>Содержимое &#9998;</Button>
                                    </Col></Row>
                                        <div>
                                            {
                                                this.state.box_oper_id.indexOf(val.id) !== -1 ?
                                                    this.state.operations_box.map((vax, i) => (
                                                        <div key={"operation" + i}>{Number(val.id) === Number(vax.box_id) ?
                                                            <div className="border-bottom d-flex"><Col className="d-flex justify-content-end">
                                                                <small><b>{vax.user}-</b>{a.subject}:</small><small>{vax.article_name}</small></Col><Col className=" d-flex col-3 justify-content-end "><small>{vax.num + " шт."}</small></Col></div> : ''}</div>
                                                    )) : <b className="text-danger">Пустой короб!</b>}
                                        </div>
                                    </div>
                                ))}
                            </Container>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            ))
        )
    }
    delete_box = (x) => {
        if (window.confirm('Вы действительно хотите удалить короб: ' + x.name + ' и все его содержимое?')) {
            axios.post(DOMEN_SERVER + TASK_DELETE_BOX, { box_id: Number(x.box_id), email: this.state.email }).then(res => {

                this.detal_box();
            }).catch(() => { alert('Не удалось выполнить операцию') });
        }
    }
    addPosition = (x) => {
        var title = 'Без формирования';
        if (x.razbivka === 1) {
            this.setState({ razb: 1 })
            if (this.state.box === '' && x.new === 1) {
                return this.newBox(x.application_id)
            }
            this.setState({ box: x.box_name, box_id: x.box_id });
            title = 'Короб: ' + x.box_name + '||' + x.user;

        }
        const box_data = this.state.box_data;
        const index = box_data.findIndex(vv => vv.id === x.box_id);

        this.articles(x.application_id);
        this.setState({ modalTitle: title, index: index });
        this.modalAddCShow();
    }
    newBox = async (a) => {
        if (this.state.box_q > 0 && this.state.box_q < 11) {
            this.setState({ spiner: '' })
            await axios.post(DOMEN_SERVER + TASK_CREATE_BOX, { email: this.state.email, application_id: a, box_q: this.state.box_q }).then(res => {
                this.detal_box();
            }).catch(() => { alert('Не удалось добавить короб'); this.setState({ spiner: 'hidden' }) });
        } else {
            alert('Вы можете разом добавить только от 1 до 10 коробов');
        }

    }
    articles = async (id) => {
        this.setState({ spiner: '' })
        await axios.post(DOMEN_SERVER + TASK_APP_ARTICLES, { id: id }).then(res => {
            this.setState({ spiner: 'hidden', articles: res.data });
        }).catch(() => { alert('Не удальось загрузить артиулы'); this.setState({ spiner: 'hidden' }) });

    }
    detal_box = () => {
        this.state.operations_box.length = 0;
        this.state.box_data.length = 0;
        this.setState({ spiner: '' });
        axios.post(DOMEN_SERVER + TASK_DETAIL_BOX, { email: this.state.email, application_id: this.state.application_id }).then(res => {
            res.data.operations_box.map((val) => (this.state.box_oper_id.push(val.box_id)));
            this.setState({
                info_operations: res.data.info,
                box_data: res.data.box_data,
                box_count: Number(res.data.box_data[0].id),
                operations_box: res.data.operations_box,
                spiner: 'hidden'
            });
        }).catch(() => { alert('Не удальось загрузить артиулы'); this.setState({ spiner: 'hidden' }) });
    }
    articleChange = (x) => {
        this.setState({ article_id: x.value });
    }
    addArticle = () => {
        const article_id = this.state.article_id;
        const num = this.state.num;
        if (article_id !== '' && num > 0) {
            this.setState({ spiner: '' });
            axios.post(DOMEN_SERVER + TASK_CREATE_OPTION, { email: this.state.email, box_id: this.state.box_id, application_id: this.state.application_id, article_id: this.state.article_id, num: this.state.num }).then(res => {
                this.operations();
                this.detal_box();
                this.setState({ spiner: 'hidden', num: '' });
            }).catch(() => { alert('Ошибка, не удалось сохранить запись'); this.setState({ spiner: 'hidden' }) });
        } else {
            alert('Укажите артикул и количество');
        }
    }

    delete_option = (x) => {
        axios.post(DOMEN_SERVER + TASK_DELETE_OPTION, { id: x }).then(res => {
            this.operations();
            this.detal_box();
        }).catch(() => { alert('Не удалось выполнить операцию') });
    }
    primer = (x) => {
        this.setState(val => ({ primer: x, box: '', application_id: x, box_id: 0 }), (() => this.detal_box()));
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState(val => ({ email: store.email }), (() => this.applications(1)));
    }
    operations = () => {
        this.state.operations.length = 0;
        axios.post(DOMEN_SERVER + TASK_OPERATIONS_BOX, { box_id: this.state.box_id, id: this.state.application_id, email: this.state.email }).then(res => {
            this.setState({ spiner: 'hidden', operations: res.data });
        }).catch(() => { alert('Не удальось загрузить артиулы'); this.setState({ spiner: 'hidden' }) });
    }

    render() {
        this.accardion();
        return (<>
            <div key="loadDiv" className="overlay" hidden={this.state.spiner}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                </div>
                <span className="m-2"> Loading...</span>
            </div>

            {this.state.modal}
            {this.state.spiner2}
            <h1 className="text-success">Активные заявки</h1>
            <Accordion className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-4 ">
                {this.state.aplications.map(val => (val))}
            </Accordion>
            <div className=" mt-3">
                {this.state.paginate.map(val => (val))}
            </div>
            <Modal show={this.state.modalAdd} fullscreen={true} onHide={this.modalAddClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Ваши записи</h6>
                    <Table size="sm">
                        <tbody>
                            <tr className="bg-primary text-white ">
                                <th><small>#</small></th><th><small>Артикул</small></th><th><small>Кол-во</small></th><th></th>
                            </tr>
                            {this.state.operations.map((val, i) => (<tr key={val.operation_id} className="ArtModalBG text-dark"><td><small>{i + 1 + "."}</small></td><td><small>{val.article_name}</small></td><td ><b><div className="d-flex justify-content-center">{val.num}</div></b></td>
                                <td ><div className="d-flex justify-content-end"><Button size="sm" onClick={() => this.delete_option(val.operation_id)} className="mb-2" variant="danger">&#10006;</Button></div></td></tr>))}
                        </tbody>
                    </Table>
                    <Modal.Footer>
                        <div className="w-100 justify-content-center">
                            <div className="border justify-content-center text-center bg-info">
                                <h3>Панель записи</h3>
                                <div className="d-flex justify-content-center"><Select options={this.state.articles} placeholder="Артикул клиента" onChange={this.articleChange} className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-4 m-3"></Select></div>
                                <div className="d-flex justify-content-center "><div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-4 m-3"><Form.Control className="" size="lg" name="num" value={this.state.num} placeholder="Количество" type="number" onChange={this.inputChange} /></div></div>
                                <div className="d-flex justify-content-center"><Button size="lg" className="m-3" variant="success" onClick={this.addArticle}>Добавить</Button></div>
                            </div>
                            <div className="justify-content-center bg-secondary text-center mt-5 w">

                                <Carousel
                                    defaultActiveIndex={this.state.index}
                                    interval={null}
                                >
                                    {this.state.razb === 1 ? this.state.box_data.map((val, e) => (
                                        <Carousel.Item onClick={() => this.addPosition({ razbivka: 1, application_id: this.state.application_id, new: 0, box_id: Number(val.id), box_name: val.name, user: val.user })} key={"Carousel" + e}>
                                            <img width={300} height={200} alt="Короб" src={BoxImg} />
                                            <Carousel.Caption>
                                                <b className="text-dark">{val.name + '||' + val.user}</b>
                                                {
                                                    this.state.box_oper_id.indexOf(val.id) !== -1 ?

                                                        this.state.operations_box.map((vax, i) => (
                                                            Number(val.id) === Number(vax.box_id) ?
                                                                <div key={'st' + i}><small><b>{vax.user}-</b>{vax.article_ss + '-' + vax.num + "шт; "}</small></div> : ''
                                                        )) : <div ><small><b>Пустой короб!</b></small></div>}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    )) : ''}
                                </Carousel>
                            </div>

                        </div>

                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>)
    }

}

//Завершенные заявки
export class СompletedApplications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', modalAdd: false, modalTitle: '', box: '', spiner: 'hidden', articles: [],
            operations: [], application_id: 0, opp: '', info_operations: [], box_data: [], operations_box: [],
            app: [], aplications: [], paginate: [], paginate: [], id: '', box_id: 0
        };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    modalAddClose = () => this.setState(val => ({ modalAdd: false, box: '', box_id: 0, num: '' }), (() => this.detal_box()));
    modalAddCShow = () => this.setState(val => ({ modalAdd: true }), (() => this.operations()));
    applications = async (page) => {
        const arr = [2, 4, 5];
        this.setState({ spiner: '' });
        const res = await axios.post(DOMEN_SERVER + MANEGMENT_APLICATIONS, { status_id: arr, page: page.page }).then(res => {
            const current_page = Number(res.data.cur_page);
            const last_page = Number(res.data.last_page);
            this.state.paginate.length = 0;
            if (this.state.paginate.length === 0) {
                this.state.paginate.push(<Button key={current_page * 20} variant="outline-dark" className="ms-1" size="sm" onClick={() => this.applications({ page: 1 })} >&laquo;</Button>);
                if ((current_page - 2) > 0) { this.state.paginate.push(<Button key={current_page - 2} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page - 2 })} >{current_page - 2}</Button>,) }
                if ((current_page - 1) > 0) { this.state.paginate.push(<Button key={current_page - 1} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page - 1 })} >{current_page - 1}</Button>,) }
                this.state.paginate.push(<Button key={current_page} disabled size="sm" className="ms-1" variant="light">{current_page}</Button>,);
                if ((current_page + 1) <= last_page) { this.state.paginate.push(<Button key={current_page + 1} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page + 1 })}  >{current_page + 1}</Button>,) }
                if ((current_page + 2) <= last_page) { this.state.paginate.push(<Button key={current_page + 2} className="ms-1" size="sm" variant="outline-dark" onClick={() => this.applications({ page: current_page + 2 })} >{current_page + 2}</Button>,) }
                this.state.paginate.push(<Button key={current_page * 10} variant="outline-dark" className="ms-1" size="sm" onClick={() => this.applications({ page: last_page })} >&raquo;</Button>);
            }

            this.setState({ app: res.data.data });
            this.setState({ spiner: 'hidden' });
        });
    }
    accardion = () => {
        this.state.aplications.length = 0;
        this.state.aplications.push(
            this.state.app.map((a, i) => (
                <Accordion.Item key={i} eventKey={i} >
                    <Accordion.Header onClick={() => this.primer(a.id)}><small className="text-dark">№:{a.id + "|"}{a.organization + ">"}{a.subject}</small></Accordion.Header>
                    <Accordion.Body>
                        <Container>
                            <Row>
                                <Col className="d-flex justify-content-end col-12">
                                    <small className="m-1"><i>Создал: {a.name + ' ' + a.created_at}</i></small>
                                    <small className="m-1"><i>Обновил: {a.manager + ' ' + a.updated_at}</i></small>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end col-2">
                                    <b><small>Описание:</small></b>
                                </Col>
                                <Col className="d-flex justify-content-start col-10">
                                    <small>{a.description}</small>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end col-2">
                                    <small><b>Операции:</b></small>
                                </Col>
                                <Col className="d-flex justify-content-start col-9">
                                    <small>{a.razbivka === 0 ? a.services : a.services}</small>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="col-11">
                                    <Row>
                                        <Col className="d-flex justify-content-start text-primary"><small><b>Выполнено: короба {this.state.info_operations.box_all > 0 ? this.state.info_operations.box_all : 0}, товар {this.state.info_operations.num_all > 0 ? this.state.info_operations.num_all : 0}</b></small>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="d-flex justify-content-start text-info"><small><b>Ваш вклад: короба {this.state.info_operations.box_me > 0 ? this.state.info_operations.box_me : 0}, товар {this.state.info_operations.num_me > 0 ? this.state.info_operations.num_me : 0}</b></small>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="d-flex justify-content-start text-success"><small> <b>Ваш бонус: короба: {this.state.info_operations.box_sum_rate > 0 ? this.state.info_operations.box_sum_rate : 0}, товар: {this.state.info_operations.sub_sum_rate > 0 ? this.state.info_operations.sub_sum_rate : 0} Итого: {this.state.info_operations.sum_rate_all}</b></small>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Container size="sm" className="mt-3 ">

                                {this.state.box_data.map((val, e) => (
                                    <Row key={"box" + e} className="mt-2 mb-3 border ">
                                        <Col className="col-1 d-flex  justify-content-end"><b>{e + 1 + ')'}</b></Col><Col className="d-flex col-9 justify-content-start"><small ><b><i><u>{val.name}</u></i> || </b> {val.user}</small></Col><Col className="d-flex col-2 justify-content-end">
                                            {Number(val.id) !== 0 ? <>
                                                <Button className="ms-2 mb-2 mt-1" size="sm" variant="primary" onClick={() => this.pdf_box({ application_id: a.id, box_id: Number(val.id) })}>Print</Button>
                                            </> : ""}</Col>
                                        <div>
                                            {this.state.operations_box.map((vax, i) => (
                                                <Row key={"operation" + i}>{Number(val.id) === Number(vax.box_id) ? <div className="border-bottom d-flex"><Col className="d-flex col-3 justify-content-end"><small>{a.subject}:</small></Col><Col className="d-flex col-6 justify-content-start ms-1"><small>{vax.article_name}</small></Col><Col className=" d-flex col-3 justify-content-end "><small>{vax.num + " шт."}</small></Col></div> : ""}</Row>
                                            ))}
                                        </div>
                                    </Row>
                                ))}
                            </Container>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            ))
        )
    }
    pdf_box = (a) => {
        document.location.href = DOMEN_SERVER + '/managment/shk_box?application_id=' + a.application_id + '&box_id=' + a.box_id;
    }


    detal_box = () => {
        this.state.operations_box.length = 0;
        this.state.box_data.length = 0;
        this.setState({ spiner: '' });
        axios.post(DOMEN_SERVER + TASK_DETAIL_BOX, { email: this.state.email, application_id: this.state.application_id }).then(res => {
            this.setState({
                info_operations: res.data.info,
                box_data: res.data.box_data,
                operations_box: res.data.operations_box,
                spiner: 'hidden'
            });
        }).catch(() => { alert('Не удальось загрузить артиулы'); this.setState({ spiner: 'hidden' }) });
    }


    primer = (x) => {
        this.setState(val => ({ primer: x, box: '', application_id: x, box_id: 0 }), (() => this.detal_box()));
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState(val => ({ email: store.email }), (() => this.applications(1)));
    }
    operations = () => {
        this.state.operations.length = 0;
        axios.post(DOMEN_SERVER + TASK_OPERATIONS_BOX, { box_id: this.state.box_id, id: this.state.application_id, email: this.state.email }).then(res => {
            this.setState({ spiner: 'hidden', operations: res.data });
        }).catch(() => { alert('Не удальось загрузить артиулы'); this.setState({ spiner: 'hidden' }) });
    }

    render() {
        this.accardion();
        return (<>
            <div key="loadDiv" className="overlay" hidden={this.state.spiner}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                </div>
                <span className="m-2"> Loading...</span>
            </div>
            <h1 className="text-primary">Завершенные заявки</h1>
            <Accordion className="col-11 col-sm-9 col-md-6 col-lg-5 col-xl-4 ">
                {this.state.aplications.map(val => (val))}
            </Accordion>
            <div className=" mt-3">
                {this.state.paginate.map(val => (val))}
            </div>
        </>)
    }

}

//Артикулы
export class Article extends React.Component {
    constructor(props) {
        super(props);
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        const col = [{ field: 'brand', headerName: 'Бренд', width: 150, headerAlign: 'center', editable: true, },
        { field: 'title', headerName: 'Название', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'subject', headerName: 'Категория', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'code', headerName: 'Баркод', width: 120, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'name', headerName: 'Артикул', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'colors', headerName: 'Цвет', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'size', headerName: 'Размер', width: 100, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'rus_size', headerName: 'Рос. размер', width: 100, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'compound', headerName: 'Состав', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'country', headerName: 'Страна', width: 120, headerAlign: 'center', align: 'center', editable: true, },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={this.handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        sx={{
                            color: 'red',
                        }}
                        onClick={() => this.handleDeleteClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<PrintIcon />}
                        label="Print"
                        className="textPrimary"
                        onClick={this.printShk(id)}
                        color="inherit"
                    />,

                ];
            },

        },
        ];
        this.state = {
            customStyles: customStyles, orgList: [], rows: [], col: col, orglabel: '', email: '', organization_id: '',
            json: '', rowModesModel: {}, modal: '', orgName: ''
        };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    CustomToolbar = (props) => {
        var name = this.state.orglabel;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Артикулы_' + name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />

            </GridToolbarContainer>
        );
    }

    orgChange = (event) => {
        this.setState({ orglabel: event.label, organization_id: event.value, orgName: event.name });
        this.rows(event.value);
    }
    rows = (id) => {
        let organization_id;
        if (id != null) {
            organization_id = id;
        } else {
            organization_id = this.state.organization_id
        }
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + TASK_ARTICLES_ALL_REQUEST, { organization_id: organization_id }).then(res => {
            this.setState({ spiner: '', rows: res.data, });
        });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data, spiner: '' });
        });
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        axios.post(DOMEN_SERVER + TASK_UPDATE_ARTICLE, {
            email: this.state.email, organization_id: this.state.organization_id, brand: updatedRow.brand,
            id: updatedRow.id, subject: updatedRow.subject, code: updatedRow.code, name: updatedRow.name, size: updatedRow.size,
            rus_size: updatedRow.rus_size, color: updatedRow.colors, country: updatedRow.country
        }).catch(res => {
            alert(res.response.data.message);
        });
        return updatedRow;
    }
    handleEditClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    };
    handleSaveClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => () => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }

    };
    handleDeleteClick = (id) => {
        if (window.confirm('Вы действительно хотите безвозвратно удалить артикул № ' + id + ' ?')) {
            axios.post(DOMEN_SERVER + TASK_DELETE_ARTICLE, { id: id }).then(res => {
                if (res.data.status === false) {
                    alert(res.data.message);
                } else {
                    this.setState({ rows: this.state.rows.filter((row) => row.id !== id) });
                }
            });
        }
    }
    printShk = (id) => () => {

        this.setState({ spiner: <Spiner /> })

        const Row = this.state.rows.find((row) => row.id === id);
        this.setState({ modal: <LabelProduct Row={Row} clearModal={this.clearModal} orgName={this.state.orgName} />, });


    };

    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ setRowModesModel: newRowModesModel });
    };
    clearModal = () => {
        this.setState({ modal: '' });
        const timeId = setTimeout(() => {
            this.setState({ spiner: '' })
        }, 2000);
    }
    render() {
        let addArticle;
        let rows;
        if (this.state.organization_id > 0) {
            addArticle = <AddArticle organization_id={this.state.organization_id} rows={this.rows} />;
        }
        if (this.state.rows.length > 0) {
            rows = <div className="w-100 p-4">
                <Box sx={{ width: '100%', }}>
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        editMode="row"
                        processRowUpdate={this.processRowUpdate}
                        onProcessRowUpdateError={this.handleProcessRowUpdateError}
                        rowModesModel={this.state.rowModesModel}
                        onRowModesModelChange={this.handleRowModesModelChange}
                    />
                </Box>
            </div>;
        }
        return (
            <>
                {this.state.spiner}
                {this.state.modal}

                <div className="col-12 col-sm-12 col-md-11 col-lg-8 col-xl-6 ">
                    <h1>Артикулы</h1>
                    <Select className="w-100 m-1" options={this.state.orgList} styles={this.state.customStyles} placeholder="Организация" onChange={this.orgChange} />
                </div>
                {addArticle}
                {rows}

            </>
        );
    }
}

//Этикетки
export class LabelProduct extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.printPdf();
    }
    getTargetElement = () => document.getElementById('content');
    printPdf = () => {
        this.setState({ spiner: <Spiner /> })
        const Row = this.props.Row;
        if (/^[0-9]+$/.test(Row.code) && Row.code.length === 13) {
            var sum = 0;
            var controller = 1;
            for (let i = 0; i < 12; i++) {
                sum = sum + (Row.code[i] * controller);
                if (controller === 1) {
                    controller = 3;
                } else {
                    controller = 1;
                }
            }
            sum = sum / 10;
            sum = sum.toString();
            sum = 10 - sum[sum.length - 1]
            if (sum == Row.code[12]) {
                JsBarcode("#barcode", Row.code, { format: "EAN13", height: 45, width: 2, margin: 0 });
            } else {
                JsBarcode("#barcode", Row.code, { format: "CODE128", height: 45, width: 2, margin: 0 });
            }

        } else {
            JsBarcode("#barcode", Row.code, { format: "CODE128", height: 45, width: 2, margin: 0 });
        }

        const options = {
            method: 'open',

            resolution: Resolution.MEDIUM,
            page: {
                // margin is in MM, default is Margin.NONE = 0.'SMALL'
                margin: 1,
                unit: 'mm',
                format: [40, 58],
                // default is 'portrait','landscape'
                orientation: 'landscape',
            },
            canvas: {
                mimeType: 'image/png',
                qualityRatio: 1
            },
            overrides: {
                pdf: {
                    compress: true
                },
                canvas: {
                    useCORS: true
                }
            },
        };

        generatePDF(this.getTargetElement, options);
        this.props.clearModal();
    }
    render() {
        let title;
        let size;
        let colors;
        let compound;
        let country;
        if (this.props.Row.title != null) {
            title = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>{this.props.Row.title}</small></h6></div>;
        } else {
            title = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>{this.props.Row.subject}</small></h6></div>;
        }
        if (this.props.Row.size != null) {
            size = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>Размер: {this.props.Row.size}</small></h6></div>;
        }
        if (this.props.Row.rus_size != null) {
            size = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>Рос. размер: {this.props.Row.rus_size}</small></h6></div>;
        }
        if (this.props.Row.colors != null) {
            colors = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>Цвет: {this.props.Row.colors}</small></h6></div>;
        }
        if (this.props.Row.compound != null) {
            compound = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>Состав: {this.props.Row.compound}</small></h6></div>;
        }
        if (this.props.Row.country != null) {
            country = <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>Страна: {this.props.Row.country}</small></h6></div>;
        }
        return (<>
            <div id="content" className="shkContent m-0">
                <div className="m-0 ">
                    <div><svg id="barcode" ></svg></div>
                    <div className="m-0 p-0"><h6 className="m-0 p-0"><small>{this.props.orgName}</small></h6></div>
                </div>
                {title}
                <div className="m-0 p-0 text-start"><h6 className="m-0 p-0"><small>Артикул: {this.props.Row.name}</small></h6></div>
                {size}
                {colors}
                {compound}
                {country}
            </div>
        </>);
    }
}

//Импорт с WB
export class ImportWb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', spiner: '', json: '',
        };
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email })
    }
    xlsxRead = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const workSheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
                delete json[0];
                delete json[1];
                delete json[3];
                const DataArray = [];
                json.map((row, i) => {
                    if (i != 2) {

                        if (row[0] != null) {
                            const jsonVal = {};
                            json[2].forEach((element, index) => {
                                jsonVal[element] = row[index];
                            });
                            DataArray.push(jsonVal);
                        }
                    }
                });
                var newJson = JSON.parse(JSON.stringify(DataArray));
                this.setState({ json: newJson });
            }
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
    push = () => {
        this.setState({ spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + TASK_WB_IMPORT_ARTICLES, { email: this.state.email, json: this.state.json, organization_id: this.props.organization_id }).then(res => {
            this.setState({ spiner: '' });
            this.props.closeModal();
            alert('Импорт завершен: ' + res.data.message);
        });
    }
    handleClose = () => { this.props.closeModal(); }
    render() {
        return (<>
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Импорт товаров из xlsx-файла Wildberries</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>В личном кабинете WB файл можно скачать следующим образом:</p>
                        <p>1.Зайти в раздел «Карточки товара»;</p>
                        <p>2.Выбрать товары в списке товаров, которые хотите импортировать, или выбрать все;</p>
                        <p>3.После выбора в появившемся окне нажать «Массовое редактирование»;</p>
                        <p>4.На открывашейся странице нажать кнопку «Выгрузить в Excel». <a href="https://wbarcode.ru/articles/import-tovarov-iz-xlsx-fayla-wildberries" target="_blank">Подробно</a></p>
                    </div>
                    <div>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Control type="file" onChange={this.xlsxRead} />
                        </Form.Group>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Отмена</Button>
                        <Button variant="primary" onClick={this.push}>Загрузить</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>);
    }
}

//Добавить артикул
export class AddArticle extends React.Component {
    constructor(props) {
        super(props);
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        this.state = {
            email: '', spiner: '', organization_id: '', countries: [], subjects: [], customStyles: customStyles,
            name: '', code: '', size: '', subject_id: '', brand: '', color: '', country_id: '', compound: '', modal: '',
        };

        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) { this.setState({ [event.target.name]: event.target.value }); }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner />, organization_id: this.props.organization_id });
        axios.post(DOMEN_SERVER + TASK_SUBJECTS_COUNTRY).then(res => {
            this.setState({ countries: res.data.countries, subjects: res.data.subjects, spiner: '' });
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.organization_id !== prevProps.organization_id) {
            this.setState({ name: '', code: '', size: '', brand: '', color: '', compound: '', modal: '', });
        }
    }
    subjectChange = (event) => { this.setState({ subject_id: event.value }); }
    countriesChange = (event) => { this.setState({ country_id: event.value }); }
    importWb = () => {
        this.setState({ modal: <ImportWb organization_id={this.props.organization_id} closeModal={this.closeModal} /> });
    }
    closeModal = () => {
        this.setState({ modal: '' });
        this.props.rows();
    }
    saveArticle = () => {
        if (this.state.name != '' && this.state.subject_id != '' && this.state.code != '') {
            axios.post(DOMEN_SERVER + TASK_CREATE_ARTICLE, {
                email: this.state.email, organization_id: this.props.organization_id,
                subject_id: this.state.subject_id, code: this.state.code, name: this.state.name,
                size: this.state.size, brand: this.state.brand, color: this.state.color, country_id: this.state.country_id, compound: this.state.compound
            }).then(res => {
                //alert(res.data.message);
                this.setState({ code: '', size: '' });
                this.props.rows();
            });

        } else {
            alert('Обязательное значение не указано')
        }
    }
    render() {

        return (<>
            {this.state.spiner}
            {this.state.modal}
            <div className="col-sm-12 col-md-11 col-lg-9 col-xl-6">
                <div className="mb-1 text-start" ><cite >* - обязательное к заполнению</cite>
                    <div className="d-flex">
                        <Form.Control key={1} id="1" type="text" name="brand" placeholder="Бренд" className="w-100 me-1" value={this.state.brand} onChange={this.inputChange} />
                        <Select options={this.state.subjects} styles={this.state.customStyles} className="w-100 me-1" placeholder="Категория*" onChange={this.subjectChange} />
                        <Form.Control key={2} id="1" type="text" name="name" className="w-100 me-1" placeholder="Артикул*" value={this.state.name} onChange={this.inputChange} />
                    </div></div>
                <div className="mb-1 " >
                    <div className="d-flex">
                        <Form.Control key={3} id="1" type="text" name="code" className="w-100 me-1" placeholder="Баркод*" value={this.state.code} onChange={this.inputChange} />
                        <Form.Control key={4} id="1" type="text" name="size" className="w-100 me-1" placeholder="Размер" value={this.state.size} onChange={this.inputChange} />
                        <Form.Control key={5} id="1" type="text" name="color" className="w-100 me-1" placeholder="Цвет" value={this.state.color} onChange={this.inputChange} />
                    </div></div>
                <div className="mb-1 " >
                    <div className="d-flex">
                        <Form.Control key={6} id="1" type="text" className="w-100 me-1" name="compound" placeholder="Состав" value={this.state.compound} onChange={this.inputChange} />
                        <Select options={this.state.countries} styles={this.state.customStyles} className="w-100 me-1" placeholder="Страна" onChange={this.countriesChange} />
                        <Button variant="primary" size="sm" onClick={this.saveArticle}>Добавить</Button>
                    </div></div>
                <div className="mb-1 " >
                    <div className="d-flex">
                        <Button variant="success" size="sm" onClick={this.importWb}>Импорт WB</Button>
                    </div></div>

            </div>
        </>);
    }
}

