import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from "react-bootstrap/Dropdown";
import * as FileSlaver from "file-saver";
import XLSX from "sheetjs-style"
import {
    DOMEN_SERVER, MANEGMENT_COUNTERPARTIES, MANEGMENT_COUNTERPARTY,
    MANEGMENT_UPDATE_COUNTERPARTY, MANEGMENT_UPDATE_COUNTERPARTY_PHONE,
    MANEGMENT_ORG_LIST, MANEGMENT_ORG_CREATE, MANEGMENT_CREATE_COUNTERPARTY,
    MANEGMENT_CREATE_SUBJECT, MANEGMENT_SUBJECTS, TASK_ORG_LIST, MANEGMENT_SUBJECTS_ORG,
    MANEGMENT_SERVICE_APP, MANEGMENT_CREATE_APP, MANEGMENT_APLICATIONS, MANEGMENT_UPDATE_APP_ST,
    MANEGMENT_APP_SUB, MANEGMENT_UPDATE_APP, MANEGMENT_EXEL_RAZBIVKA, MANEGMENT_SERVICE_PRICE, MANEGMENT_MERCHANDIS_CREATE,
    MANEGMENT_MERCHANDIS_ROW, MANEGMENT_SMENA, MANEGMENT_SMENA_INSERT, MANEGMENT_SMENA_TOP, MANEGMENT_UPDATE_ORGANIZATION, MANEGMENT_OPERATIONS_APPLICATION, MANEGMENT_UPDATE_OPERATION_NUM, MANEGMENT_PRINT_SHK_BOX
} from "../Config/Base";
import { Card, Form } from "react-bootstrap";
import { PriceList, Spiner } from "./Accounting";
import { Box } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { DataGrid, GridToolbarContainer, GridToolbarExport, ruRU, GridRowModes, GridActionsCellItem, } from "@mui/x-data-grid";
import { SessionEndend } from "./Auth";
import { MyPagginate } from "./Template";


export class Clients extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'id', headerName: 'ID', width: 60, headerAlign: 'center', },
        { field: 'name', headerName: 'Название', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        { field: 'inn', headerName: 'ИНН', width: 150, headerAlign: 'center', align: 'center', editable: true, },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={this.handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },

        },
        ];
        this.state = {
            counterparties: [], uemail: '', id: '', code: '', name: '', phone: '', phone2: '', email: '', col: col,
            nname: '', nphone: '', nphone2: '', nemail: '', created_at: '', disabled: '', hiddeninfo: 'hidden', hiddenLoad: 'hidden',
            showM1: false, showM2: false, showM3: false, showM4: false, orgList: [], orgName: '', orgInn: '', orglabel: '', rowModesModel: {}, setRowModesModel: {}
        };
        this.inputChange = this.inputChange.bind(this);
    }
    handleClose = () => this.setState({ showM1: false });
    handleShow = () => this.setState({ showM1: true });
    handleClose2 = () => this.setState({ showM2: false });
    handleShow2 = () => this.setState({ showM2: true });
    handleClose3 = () => this.setState({ showM3: false });
    handleShow3 = () => this.setState({ showM3: true });
    handleClose4 = () => this.setState({ showM4: false });
    handleShow4 = () => this.setState({ showM4: true, nname: '', nphone: '', nphone2: '', nemail: '' });

    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ uemail: store.email })
        this.setState({ hidden: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_COUNTERPARTIES).then(res => {
            const counterparties = res.data;
            this.setState({ counterparties });
            this.setState({ hidden: 'hidden' });

        });
    }

    handlechange = (e) => {
        this.setState({ hiddenLoad: '', orglabel: e.label });
        axios.post(DOMEN_SERVER + MANEGMENT_COUNTERPARTY, { id: e.value }).then(res => {
            this.setState({ orgList: [] });
            this.setState({ id: e.value });
            const code = res.data[0].code;
            const name = res.data[0].name;
            const phone = res.data[0].phone;
            const phone2 = res.data[0].phone2;
            const email = res.data[0].email;
            const created_at = new Date(res.data[0].created_at);
            this.setState({ code });
            this.setState({ name });
            this.setState({ phone });
            this.setState({ phone2 });
            this.setState({ email });
            this.setState({ created_at });
            this.setState({ hiddenLoad: 'hidden' });
            this.setState({ hiddeninfo: '' });
        });
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    createcouterpaty = () => {
        this.setState({ hiddenLoad: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_CREATE_COUNTERPARTY, {
            uemail: this.state.uemail, name: this.state.nname, phone: this.state.nphone,
            phone2: this.state.nphone2, email: this.state.nemail
        }).then(res => {
            const message = res.data.message;
            alert(message);
            this.setState({ hiddenLoad: 'hidden' });
            window.location.reload();
        }).catch(() => {
            alert("Не удолось добавить, ошибка на стороне сервера!");
            this.setState({ hiddenLoad: 'hidden' });
        });

    }
    savecouterpaty = () => {
        this.setState({ hiddenLoad: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_UPDATE_COUNTERPARTY, {
            uemail: this.state.uemail, id: this.state.id, name: this.state.name,
            phone2: this.state.phone2, email: this.state.email
        }).then(res => {
            const message = res.data.message;
            alert(message);
            this.setState({ disabled: '' });
            this.setState({ hiddenLoad: 'hidden' });
        }).catch(() => {
            alert("Не удолось обновить, ошибка на стороне сервера!");
            this.setState({ hiddenLoad: 'hidden' });
        });
    }
    savecouterpatyPhone = () => {
        this.setState({ hiddenLoad: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_UPDATE_COUNTERPARTY_PHONE, { uemail: this.state.uemail, id: this.state.id, phone: this.state.phone }).then(res => {
            const message = res.data.message;
            alert(message);
            this.setState({ hiddenLoad: 'hidden' });
            this.handleClose2();
        }).catch(() => {
            alert("Не удолось обновить, ошибка на стороне сервера!");
            this.setState({ hiddenLoad: 'hidden' });
        });
    }

    saveorg = () => {
        this.setState({ hiddenLoad: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_ORG_CREATE, { uemail: this.state.uemail, id: this.state.id, name: this.state.orgName, inn: this.state.orgInn }).then(res => {
            const message = res.data.message;
            alert(message);
            this.orgList();
            this.setState({ hiddenLoad: 'hidden' });
            this.handleClose3();
        }).catch(() => {
            alert("Не удолось сохранить, ошибка на стороне сервера!");
            this.setState({ hiddenLoad: 'hidden' });
        });
    }
    orgList = () => {
        this.setState({ hiddenLoad: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_ORG_LIST, { id: this.state.id }).then(res => {
            const orgList = res.data;
            this.setState({ orgList: orgList, rows: orgList });
            this.setState({ hiddenLoad: 'hidden' });
        });
    }
    CustomToolbar = (props) => {
        var name = this.state.orglabel;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Организации_' + name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />

            </GridToolbarContainer>
        );
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        axios.post(DOMEN_SERVER + MANEGMENT_UPDATE_ORGANIZATION, { uemail: this.state.uemail, id: updatedRow.id, name: updatedRow.name, inn: updatedRow.inn });
        return updatedRow;
    }
    handleEditClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    };
    handleSaveClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => () => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }

    };
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ setRowModesModel: newRowModesModel });
    };
    render() {
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        let orglist;
        if (this.state.orgList.length > 0) {
            orglist = <Box sx={{ width: '100%', }}>
                <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                    slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    editMode="row"
                    processRowUpdate={this.processRowUpdate}
                    onProcessRowUpdateError={this.handleProcessRowUpdateError}
                    rowModesModel={this.state.rowModesModel}
                    onRowModesModelChange={this.handleRowModesModelChange}
                />
            </Box>;
        }
        return (<>
            {/* Заставка */}
            <div className="overlay" hidden={this.state.hiddenLoad}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                </div>
                <span className="m-2"> Loading...</span>
            </div>
            {/* Выбрать контрагента */}
            <div className=" col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 ">
                <h1>Контрагенты (Заказчики)</h1>
                <div className="d-flex">
                    <Select className="w-100  m-1"
                        onChange={this.handlechange}
                        placeholder='Контрагенты'
                        options={this.state.counterparties}
                        styles={customStyles}
                    /> <Button variant="dark" onClick={this.handleShow4}>Новый+</Button></div>

                <div hidden={this.state.hiddeninfo}>
                    <div>
                        <Button className="m-2" onClick={this.handleShow2}>Смена номера</Button>
                        <Button className="m-2" onClick={this.handleShow}>Редактировать</Button>
                    </div>
                    <Table>
                        <tbody>
                            <tr><td className="Inform">Код:</td><td><b>{this.state.code}</b></td></tr>
                            <tr><td className="Inform">Имя:</td><td><b>{this.state.name}</b></td></tr>
                            <tr><td className="Inform">Основной номер:</td><td><u><b>{this.state.phone}</b></u></td></tr>
                            <tr><td className="Inform">Доп-ный номер:</td><td><b>{this.state.phone2}</b></td></tr>
                            <tr><td className="Inform">Email:</td><td><b>{this.state.email}</b></td></tr>
                            <tr><td className="Inform">Добавлен:</td><td>{this.state.created_at.toLocaleString()}</td></tr>
                        </tbody>
                    </Table>

                    <div className="mt-3"><p> <b>Организации</b></p>
                        <Button className="m-1" variant="warning" onClick={this.orgList}>Загрузить список</Button>
                        <Button className="m-1" variant="warning" onClick={this.handleShow3}>Добавить</Button>
                        {/* Список организаций контрагента */}
                        {orglist}
                    </div>
                </div>
                {/* Добавить нового контрагента */}
                <Modal show={this.state.showM4} onHide={this.handleClose4}>
                    <Modal.Header closeButton>
                        <Modal.Title>Новый контрагент</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <tbody>
                                <tr>
                                    <td className="Inform">Имя*</td>
                                    <td><input type="text" className="inputCo" name="nname" placeholder="Шавкат Маликов" onChange={this.inputChange} /></td>
                                </tr>
                                <tr>
                                    <td className="Inform">Осн-й номер *</td>
                                    <td><div className="phone"> <input type='number' className="inputCo" placeholder="7 985 142 60 64" name="nphone" onChange={this.inputChange} /></div></td>
                                </tr>
                                <tr>
                                    <td className="Inform">Доп-й номер</td>
                                    <td><div className="phone"> <input type='number' className="inputCo" placeholder="996 552 30 01 92" name="nphone2" onChange={this.inputChange} /></div></td>
                                </tr>
                                <tr>
                                    <td className="Inform">Email</td>
                                    <td><input type='email' className="inputCo" placeholder="example@mail.com" name="nemail" onChange={this.inputChange} /></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose4}>Закрыть</Button>
                            <Button variant="primary" onClick={this.createcouterpaty}>Сохранить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
                {/* Редактировать контрагента */}
                <Modal show={this.state.showM1} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактировать</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table>
                            <tbody>
                                <tr><td className="Inform">Код:</td><td> <b>{this.state.code}</b></td></tr>
                                <tr>
                                    <td className="Inform">Имя:</td>
                                    <td><input type="text" className="inputCo" disabled={this.state.disabled} name="name" value={this.state.name == null ? '' : this.state.name}
                                        onChange={this.inputChange} /></td>
                                </tr>
                                <tr>
                                    <td className="Inform">Доп. номер:</td>
                                    <td><div className="phone"> <input type='number' className="inputCo" placeholder="996 552 30 01 92" name="phone2" disabled={this.state.disabled}
                                        value={this.state.phone2 == null ? '' : this.state.phone2} onChange={this.inputChange} /></div></td>
                                </tr>
                                <tr>
                                    <td className="Inform">Email:</td>
                                    <td><input type='email' className="inputCo" name="email" placeholder="example@mail.com" disabled={this.state.disabled}
                                        value={this.state.email == null ? '' : this.state.email} onChange={this.inputChange} /></td>
                                </tr>
                            </tbody>
                        </Table>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>Закрыть</Button>
                            <Button variant="primary" onClick={this.savecouterpaty}>Сохранить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                    {/* Смена основного  номера контрагента */}
                </Modal>
                <Modal show={this.state.showM2} onHide={this.handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Смена основного номера</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table><tbody><tr><td>Текущий номер:</td><td><b>{this.state.phone}</b></td></tr><tr><td>Новый:</td><td>
                            <input type="number" name="phone" placeholder="79850000000" onChange={this.inputChange} /></td></tr>
                        </tbody></Table>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose2}>Закрыть</Button>
                            <Button variant="primary" onClick={this.savecouterpatyPhone}>Сохранить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
                {/* Добавить организацию */}
                <Modal show={this.state.showM3} onHide={this.handleClose3}>
                    <Modal.Header closeButton>
                        <Modal.Title>Добавить организацию</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table><thead></thead><tbody><tr><td>Контрагент:</td><td><b>{this.state.code} - {this.state.name}</b></td></tr><tr><td>Название:</td>
                            <td><input type="text" className="inputCo1" name="orgName" placeholder={"ИП " + this.state.name + " или Nike"} onChange={this.inputChange} />
                            </td></tr><tr><td>ИНН:</td><td><input className="inputCo1" type="number" name="orgInn" placeholder="не обязательно" onChange={this.inputChange} /></td>
                            </tr></tbody><tfoot></tfoot></Table>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose3}>Закрыть</Button>
                            <Button variant="primary" onClick={this.saveorg}>Сохранить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </div>
        </>);
    }
}

export class Subject extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', hidden: 'hidden', subject: [], pagenate: [], name: '' };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    createsubject = () => {
        if (this.state.name !== '') {
            this.setState({ hidden: '' });
            axios.post(DOMEN_SERVER + MANEGMENT_CREATE_SUBJECT, { email: this.state.email, name: this.state.name }).then(res => {
                alert(res.data.message);
                this.setState({ name: '' });
                window.location.reload();
            }).catch(val => {
                alert(val.message);
            });
            this.setState({ hidden: 'hidden' })
        }
    }
    loadVal = (e) => {
        this.setState({ hidden: '', pagenate: [] });
        axios.post(DOMEN_SERVER + MANEGMENT_SUBJECTS, { page: e.page }).then(res => {
            this.setState({ subject: res.data.data, });
            const current_page = Number(res.data.current_page);
            const last_page = Number(res.data.last_page);
            if (this.state.pagenate.length === 0) {
                this.state.pagenate.push(<Pagination.First key={current_page * 20} onClick={() => this.loadVal({ page: 1 })} />);
                if ((current_page - 2) > 0) { this.state.pagenate.push(<Pagination.Item key={current_page - 2} onClick={() => this.loadVal({ page: current_page - 2 })} >{current_page - 2}</Pagination.Item>,) }
                if ((current_page - 1) > 0) { this.state.pagenate.push(<Pagination.Item key={current_page - 1} onClick={() => this.loadVal({ page: current_page - 1 })} >{current_page - 1}</Pagination.Item>,) }
                this.state.pagenate.push(<Pagination.Item key={current_page} active>{current_page}</Pagination.Item>,);
                if ((current_page + 1) <= last_page) { this.state.pagenate.push(<Pagination.Item key={current_page + 1} onClick={() => this.loadVal({ page: current_page + 1 })}  >{current_page + 1}</Pagination.Item>,) }
                if ((current_page + 2) <= last_page) { this.state.pagenate.push(<Pagination.Item key={current_page + 2} onClick={() => this.loadVal({ page: current_page + 2 })} >{current_page + 2}</Pagination.Item>,) }
                this.state.pagenate.push(<Pagination.Last key={current_page * 10} onClick={() => this.loadVal({ page: last_page })} />);
            }
        }).catch(val => {
            alert(val.message);
        });

        this.setState({ hidden: 'hidden' })
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email })
        this.loadVal({ page: 1 });
    }
    render() {

        return (
            <>
                {/* Заставка */}
                <div key="loadDiv" className="overlay" hidden={this.state.hidden}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow text-primary" role="status" ></div>
                        <div className="spinner-grow text-primary" role="status" ></div>
                        <div className="spinner-grow text-primary" role="status" ></div>
                    </div>
                    <span className="m-2"> Loading...</span>
                </div>
                <div className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3" key="bodyDiv">
                    <h1>Предметы (Категория товара)</h1>
                    <div><input className="w-90 m-1 p-2" type="text" placeholder="Новый предмет" name="name" onChange={this.inputChange} /> <Button className="w-10" onClick={this.createsubject} variant="dark">Добавить&#10010;</Button></div>

                    <Table>
                        <thead>
                            <tr><th>#</th><th>Наименование</th></tr>
                        </thead>
                        <tbody>
                            {this.state.subject.map(val => (<tr key={val.id}><td>{val.id}</td><td><b>{val.name}</b></td></tr>))}
                        </tbody>
                    </Table>
                    <Pagination >
                        {this.state.pagenate}
                    </Pagination>
                </div>
            </>
        )
    }
}

export const ExportExcel = ({ excelData, fileName, sheetName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: "A1" });
    XLSX.utils.sheet_add_aoa(ws, [["Короб", "Штрихкод", "Артикул", "Размер", "Количество"]], { origin: "A1" });
    ws["!cols"] = [{ wch: 15 }, { wch: 18 }, { wch: 12 }, { wch: 10 }, { wch: 10 }];
    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    const exelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([exelBuffer], { type: fileType });
    FileSlaver.saveAs(data, fileName + fileExtension);

}

class Operation extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'personal', headerName: 'Сотрудник', width: 120, headerAlign: 'center', },
        { field: 'box', headerName: 'Короб', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'article', headerName: 'Артикул', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'size', headerName: 'Размер', width: 80, headerAlign: 'center', align: 'center' },
        { field: 'barcode', headerName: 'Баркод', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'quantity', headerName: 'Количество', width: 120, headerAlign: 'center', align: 'center', editable: true, },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={this.handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },

        },
        ];
        this.state = { rows: [], rowModesModel: {}, setRowModesModel: {}, status: 0, col: col, show: true }
    }
    handleClose = () => {
        this.props.closeModal();
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email })
        axios.post(DOMEN_SERVER + MANEGMENT_OPERATIONS_APPLICATION, { id: this.props.id }).then(res => {
            this.setState({ rows: res.data.operations, status: res.data.status });
        });
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        const upRow = this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        this.setState({ rows: upRow });
        axios.post(DOMEN_SERVER + MANEGMENT_UPDATE_OPERATION_NUM, { id: newRow.id, num: newRow.quantity });
        return updatedRow;
    }

    handleEditClick = (id) => () => {
        this.state.status == 1 ? this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) }) : alert("Заявка завершена, редактирование невозможно")
    };
    handleSaveClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => () => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }

    };
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ setRowModesModel: newRowModesModel });
    };
    render() {
        return (<>
            <Modal show={this.state.show} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Операции по заявке № {this.props.id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>{this.props.organization}</b></p>
                    <p>Редактирование - {this.state.status == 1 ? "разрешено" : "запрещено"}</p>
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                            editMode="row"
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            processRowUpdate={this.processRowUpdate}
                            onProcessRowUpdateError={this.handleProcessRowUpdateError}
                            rowModesModel={this.state.rowModesModel}
                            onRowModesModelChange={this.handleRowModesModelChange}
                        />
                    </Box>
                </Modal.Body>
            </Modal>
        </>);
    }
}


export class Application extends React.Component {
    constructor(props) {
        super(props);
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        this.state = {
            customStyles: customStyles, orgList: [], saveApp: [], aplications: [], paginate: [], view_app: [], title: '', spiner: 'hidden',
            hidden: 'hidden', main_cont: 'hidden', pdf: [], modal: '', spiner2: '', status_id: [], orgName:'', organization_id: 0,
        };
        this.selectRef = React.createRef();
    }
    closeModal = () => {
        this.setState({ modal: '' });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.loadAplications({status_id:[1],title:" - активные"});
        this.setState({ email: store.email, spiner2: <Spiner /> });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data, spiner2: '' });
        });
    }
    newApplication = () => {
        this.state.saveApp.push(<SaveAplication key='new_modal' new={true} title="Новая заявка" />);
        this.setState({ hidden: '' });
    }
    EditApplication = (x) => {
        this.state.saveApp.push(<SaveAplication key='edit_modal' id={x.id} edit={true} title={"Заявка №:" + x.id} />);
        this.setState({ hidden: '' });
    }
    toPage = (x) => {
        this.Applications({ title: this.state.title, organization_id: this.state.organization_id, status_id: this.state.status_id, page: x });
    }
    Applications = (x) => {
        var page = 1;
        if (Number(x.page) > 0) {
            page = Number(x.page);
        }
        this.setState({ title: x.title, organization_id: x.organization_id, status_id: x.status_id, spiner2:<Spiner/> });
        this.state.paginate.length = 0;
        this.state.aplications.length = 0;
        this.state.view_app.length = 0;
        axios.post(DOMEN_SERVER + MANEGMENT_APLICATIONS, { organization_id: x.organization_id, status_id: x.status_id, page: page }).then(res => {
            this.state.aplications.push(res.data.data);
            const current_page = Number(res.data.cur_page);
            const last_page = Number(res.data.last_page);
            if (this.state.paginate.length === 0) {
                this.state.paginate.push(<MyPagginate current_page={current_page} last_page={last_page} toPage={this.toPage} />);
            }
            this.view_app();

        });

    }
    set_status = (x) => {
        axios.post(DOMEN_SERVER + MANEGMENT_UPDATE_APP_ST, { email: this.state.email, status_id: x.status_id, id: x.id }).then(res => {
            x.app === 1 ? this.loadAplications({status_id:[1],title:" - активные"}) : this.loadAplications({status_id:[2, 3, 4],title:" - завершенные"});
        });
    }
    view_app = () => {
        if (this.state.aplications.length > 0) {
            this.state.view_app.push(
                this.state.aplications[0].map((a, i) => (
                    <div key={'aplication'+i} className="border border-secondary m-1"><Table size="sm"><tbody>
                        <tr className="bg-light">
                            <th  ><span className="d-flex justify-content-start">Код:{a.id}</span></th><th >{a.organization}</th><th>
                                <Dropdown className="d-flex justify-content-end">
                                    <Dropdown.Toggle size="sm" variant={(() => {
                                        switch (a.status_id) {
                                            case 1: return "outline-success";
                                            case 2: return 'outline-primary';
                                            case 3: return 'outline-secondary';
                                            case 4: return 'outline-warning';
                                            default: return "outline-info";
                                        }
                                    })()} id="dropdown-basic">
                                        {a.status}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item hidden={a.status_id === 3 || a.status_id === 2 ? "" : "hidden"} onClick={() => this.set_status({ id: a.id, status_id: 1, app: 1 })}>Возобновить</Dropdown.Item>
                                        <Dropdown.Item hidden={a.status_id > 1 ? "hidden" : ""} onClick={() => this.EditApplication({ id: a.id })}>Редактировать</Dropdown.Item>
                                        <Dropdown.Item hidden={a.status_id > 1 ? "hidden" : ""} onClick={() => this.set_status({ id: a.id, status_id: 3, app: 2 })}>Приостановить</Dropdown.Item>
                                        <Dropdown.Item hidden={a.status_id > 1 ? "hidden" : ""} onClick={() => this.set_status({ id: a.id, status_id: 2, app: 2 })}>Завершить</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </th>
                        </tr>
                        <tr>
                            <td><span className="d-flex justify-content-end"><b>{a.subject}:</b></span></td><td colSpan={2} ><small className="d-flex justify-content-start">{a.services}</small></td>
                        </tr>
                        <tr>
                            <td><span className="d-flex justify-content-end">Описание:</span></td><td colSpan={2}><small className="d-flex justify-content-start">{a.description}</small></td>
                        </tr>
                        <tr>
                            <td><span className="d-flex justify-content-end">Создано:</span></td><td colSpan={2}><small className="d-flex justify-content-start">{a.name} - {a.created_at}</small>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="d-flex justify-content-end">Обновлено:</span></td><td colSpan={2}><small className="d-flex justify-content-start">{a.manager} - {a.updated_at}</small>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="d-flex justify-content-end">Выполнено:</span></td><td ><small className="d-flex justify-content-start">короба: {a.box}; товара: {a.operation}</small></td><td></td>
                        </tr>
                        <tr>
                            <td ></td>
                            <td colSpan={2} ><div className="d-flex justify-content-end "><Button className="ms-2 mb-2 mt-1" size="sm" variant="warning" onClick={() => this.setState({ modal: <Operation id={a.id} organization={a.organization} closeModal={this.closeModal} /> })}>Операции</Button><Button className="ms-2 mb-2 mt-1" size="sm" variant="success" onClick={() => this.toExel({ id: a.id, organization: a.organization })}>Разбивка Эксель</Button>
                                <Button className="ms-2 mb-2 mt-1" size="sm" variant="primary" onClick={() => this.toPdf({ id: a.id, box: a.box })}>ШК Коробов</Button></div></td>
                        </tr>
                    </tbody></Table></div>
                )

                )
            )
        }
        this.setState({ spiner2: '', main_cont: '' });
    }

    toExel = (a) => {

        var organization = a.organization;
        organization = organization.replaceAll(' ', '_');
        organization = organization.replaceAll('.', '_');
        axios.post(DOMEN_SERVER + MANEGMENT_EXEL_RAZBIVKA, { application_id: a.id }).then(res => {
            ExportExcel({ excelData: res.data, fileName: organization, sheetName: 'Заявка-' + a.id });
        });
    }
    getElem = () => {
        const aaaa = document.getElementById('aaaa');
        aaaa.click();
    }
    clearModal = () => {
        this.setState({ modal: '' });
    }
    toPdf = (a) => {
        if (a.box > 0) {
            window.open(DOMEN_SERVER + '/managment/shk_box?application_id=' + a.id, "_blank");
            // axios.post(DOMEN_SERVER + MANEGMENT_PRINT_SHK_BOX, { application_id: a.id }).then(res => {
            //     this.setState({ modal: <ShkBoxesPdf clearModal={this.clearModal} data={res.data}/>, });
            // });
        } else {
            alert('Нет шк коробов');
        }

    }
    orgChange = (event) => {
        this.setState({orgName:event.label});
        this.Applications({ title: this.state.title, organization_id: event.value, status_id: this.state.status_id, page: 0, valueSel:event});
    }
    loadAplications=(x)=>{
        this.setState({orgName:''});
        this.Applications({ organization_id: 0, page: 0, status_id:x.status_id, title: x.title });
    }
    render() {
        return (
            <div>
                {this.state.modal}
                {this.state.spiner2}
                <h1>Задачи {this.state.title}</h1>
                {this.state.pdf.map(val => (val))}
                <Container>
                    <Row className="d-flex gap-4">
                        <Col><Button size="lg" variant="success" onClick={() =>this.loadAplications({status_id:[1],title:" - активные"}) }>Активные</Button></Col>
                        <Col><Button size="lg" variant="warning" onClick={() =>this.loadAplications({status_id:[2, 3, 4],title:" - завершенные"})}>Завершенные</Button></Col>
                        <Col><Button size="lg" variant="info" onClick={this.newApplication}>&#10010;Создать</Button></Col>
                    </Row>
                </Container>
                <div hidden={this.state.main_cont} className="w-100  border mt-3 p-1">
                    <div><Select className="w-100 m-1"
                        options={this.state.orgList}
                        styles={this.state.customStyles}
                        placeholder="Организация"
                        onChange={this.orgChange}
                        value={null}
                        ref={this.selectRef}
                    /></div>
                    <div className="mt-3">
                        <div><b className="text-success"><u>{this.state.orgName}</u></b></div>
                        {this.state.view_app.map(val => (val))}
                        </div>
                    <div className="mt-5 d-flex justify-content-center">{this.state.paginate.map(val => (val))}</div>

                </div>
                <div hidden={this.state.hidden}>
                    {this.state.saveApp.map(val => (val))}
                </div>

            </div>
        )
    }
}

export class SaveAplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', applications_id: '', show: true, new_obj: [], sub_obj: [], service_add: [], service_obj: [], service_val_obj: [],
            service_val_data: [], new_div: 'hidden',
            sub_div: 'hidden', anotation_div: 'hidden', service_div: 'hidden', service_val_div: 'hidden', edit_div: 'hidden',
            organization_id: '', subject_id: '', organization: '', subject: '', anotation: '', razbivka: 0, spiner: ''
        };
        this.inputChange = this.inputChange.bind(this);
        this.inputChangeData = this.inputChangeData.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        this.setState({ spiner: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_SERVICE_APP, { category_id: 2 }).then(res => {
            this.state.service_obj.push(res.data);
        });
        if (this.props.new === true) {
            const new_obj = async () => {
                await axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
                    if (this.state.new_obj.length === 0) {
                        this.state.new_obj.push(<div className="mb-1" key='new_obj'><Select options={res.data} placeholder="Выберите организацию" onChange={this.sub_obj} /> </div>);
                        this.setState({ new_div: '', spiner: 'hidden' });
                    }
                });
            }
            new_obj();
        }
        if (this.props.edit === true) {
            this.state.service_val_data.length = 0;

            const edit_data = async () => {
                await axios.post(DOMEN_SERVER + MANEGMENT_APP_SUB, { id: this.props.id }).then(res => {
                    const dd = res.data.application[0];
                    const subapp = res.data.subaplication
                    const subdd = [];
                    subapp.map(x =>
                        this.state.service_obj[0].map((a, i) => {
                            if (Number(a.value) === Number(x.service_id)) {
                                subdd.push(
                                    { index: i, service_id: x.service_id, rate: x.rate, num: x.num, description: x.description }
                                );
                            }
                        }));

                    this.setState(val => ({
                        organization: dd.organization, subject: dd.subject,
                        anotation: dd.anotation, razbivka: Number(dd.razbivka), organization_id: dd.organization_id, service_val_data: subdd,
                    }), (() => { this.sub_change({ value: 0 }); }));
                });
            }

            edit_data();
        }
    }

    inputChangeData(event) {
        const data = this.state.service_val_data;
        const finData = data.find((a, i) => i === Number(event.target.id));
        finData[event.target.name] = event.target.value;
        this.setState({ service_val_data: data });
        this.service_add();
    }
    modalClose = () => { this.setState({ show: false }); window.location.reload(); }
    sub_obj = (x) => {
        this.setState({ sub_obj: [], organization_id: x.value, subject_id: '', sub_div: 'hidden', spiner: '' });
        axios.post(DOMEN_SERVER + MANEGMENT_SUBJECTS_ORG, { organization_id: x.value }).then(res => {
            this.state.sub_obj.push(<div className="mb-1" key='sub_obj'><Select options={res.data} placeholder="Выберите категорию" onChange={this.sub_change} /> </div>);
            this.setState({ sub_div: '', spiner: 'hidden' });
        });
    }
    sub_change = (x) => {
        if (x.value === 0) {
            this.setState({ spiner: 'hidden', edit_div: '' });
        }
        this.setState({ subject_id: x.value, anotation_div: '' });
        this.service_add();
    }
    search_serv = (x) => {
        let ind;
        this.state.service_obj[0].map((a, i) => { if (Number(a.value) === x) { ind = i; } });
        return ind;
    }

    service_change = (x) => {
        const data = this.state.service_val_data;
        const finData = data.find((a, i) => i === Number(x.index));
        finData.index = Number(this.search_serv(Number(x.val.value)));
        finData.service_id = Number(x.val.value);
        finData.rate = Number(x.val.rate);
        finData.num = Number(1);
        finData.description = '';
        this.setState({ service_val_data: data });
        this.service_add();
    }
    service_add = () => {
        this.state.service_add.length = 0;
        this.setState({ service_div: 'hidden' });
        if (this.state.service_add.length === 0 && this.state.service_val_data.length > 0) {
            this.state.service_add.push(
                this.state.service_val_data.map((val, index) => (<div className="m-1 border  border-secondary" key={index}><div className="mb-1 border d-flex"><span className="m-1"><b>{index + 1}.</b></span><div className="w-100  d-flex flex-row-reverse">
                    <Button size="sm" value={index} variant="warning" onClick={this.service_delete}> &#x2716;</Button></div> </div>
                    <Select className="mb-1"
                        value={this.state.service_obj[0][this.state.service_val_data[index].index]} options={this.state.service_obj[0]} id={index}
                        placeholder="Выберите сервис" onChange={(val) => this.service_change({ val: val, index: index })} />
                    <div className="d-flex mb-1">
                        <div className="m-1"><cite>Ставка руб.</cite> <Form.Control id={index} name='rate' type="number" value={this.state.service_val_data[index].rate} onChange={this.inputChangeData} /></div>
                        <div className="m-1"><cite>Кол. операций</cite> <Form.Control key={index} id={index} name='num' type="number" value={this.state.service_val_data[index].num} onChange={this.inputChangeData} /></div>
                    </div>
                    <Form.Control id={index} name='description' type="text" placeholder="Примечание" value={this.state.service_val_data[index].description} onChange={this.inputChangeData} />
                </div>))
            )
        }
        this.setState({ service_div: '' });
    }
    service_new = () => {
        this.state.service_val_data.push(
            { index: '', service_id: '', rate: '', num: '', description: '' }
        );
        this.service_add();
    }
    service_delete = (x) => {
        this.state.service_add.length = 0;
        const data = this.state.service_val_data.filter((a, i) => i !== Number(x.target.value)); this.state.service_val_data.length = 0;
        this.setState(val => ({ service_val_data: data }), () => { if (this.state.service_val_data.length > 0) this.service_add(); });
    }

    razbivka = () => {
        if (this.state.razbivka === 0) {
            this.setState({ razbivka: 1 });
        } else {
            this.setState({ razbivka: 0 });
        }
    }
    create_application = () => {
        axios.post(DOMEN_SERVER + MANEGMENT_CREATE_APP, {
            email: this.state.email, organization_id: this.state.organization_id, subject_id: this.state.subject_id,
            anotation: this.state.anotation, razbivka: this.state.razbivka, service_data: this.state.service_val_data
        }).then(res => {
            console.log(res);
            alert(res.data.message);
            this.modalClose();
        }).catch((nn) =>
            // console.log()
            alert('Не удалось сохранить')
        );
    }
    update_application = () => {
        axios.post(DOMEN_SERVER + MANEGMENT_UPDATE_APP, {
            email: this.state.email, id: this.props.id, organization_id: this.state.organization_id,
            anotation: this.state.anotation, razbivka: this.state.razbivka, service_data: this.state.service_val_data
        }).then(res => {
            alert(res.data.message);
            this.modalClose();
        }).catch(() =>
            alert('Не удалось сохранить')
        );
    }
    render() {
        return (
            <>
                <div key="loadDiv" className="overlay" hidden={this.state.spiner}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow text-primary" role="status" ></div>
                        <div className="spinner-grow text-primary" role="status" ></div>
                        <div className="spinner-grow text-primary" role="status" ></div>
                    </div>
                    <span className="m-2"> Loading...</span>
                </div>

                <Modal show={this.state.show} fullscreen={true} onHide={this.modalClose} key="modal_dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div hidden={this.state.new_div}> {this.state.new_obj.map(val => (val))}</div>
                        <div hidden={this.state.sub_div}> {this.state.sub_obj.map(val => (val))}</div>
                        <div hidden={this.state.edit_div}><p><b>{this.state.organization}</b></p></div>
                        <div hidden={this.state.edit_div}><p><b>{this.state.subject}</b></p></div>
                        <div hidden={this.state.anotation_div}> <textarea rows="3" type="text" className="w-100" maxLength="250" placeholder="Описание заявки" name="anotation" value={this.state.anotation} onChange={this.inputChange} /></div>
                        <div hidden={this.state.service_div}> {this.state.service_add.map(val => (val))}<div className="m-2">
                            <Button size="sm" variant="dark" onClick={this.service_new}>Добавить подготовку</Button>
                            <Button size="sm" hidden={this.state.razbivka === 0 ? "" : "hidden"} className="ms-2" variant="success" onClick={this.razbivka}>Включить форм-ние</Button>
                            <Button size="sm" hidden={this.state.razbivka === 1 ? "" : "hidden"} className="ms-2" variant="danger" onClick={this.razbivka}>Отключить форм-ние</Button>
                        </div></div>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.modalClose}>Закрыть</Button>
                            <Button variant="primary" hidden={this.props.new === true ? "" : "hidden"} onClick={this.create_application}>Сохранить</Button>
                            <Button variant="warning" hidden={this.props.edit === true ? "" : "hidden"} onClick={this.update_application}>Изменить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
//Продажа расходников
export class Merchandise extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'created_at', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'organization', headerName: 'Компания', width: 200, headerAlign: 'center', },
        { field: 'service', headerName: 'Расходник', width: 200, headerAlign: 'center', align: 'center', },
        { field: 'service_count', headerName: 'Количество', width: 150, headerAlign: 'center', align: 'center', },
        { field: 'accrued', headerName: 'Начисление', width: 150, headerAlign: 'center', align: 'center', },
        { field: 'salary_id', headerName: 'Зарплата №', width: 150, headerAlign: 'center', align: 'center', },
        { field: 'user', headerName: 'Исполнитель', width: 150, headerAlign: 'center', align: 'center', },];
        this.state = { email: '', rows: [], col: col, organization_id: '', service_id: '', service_count: 0, accruad: 0, price: 0, rate: 0, total_sum: 0, modal: '' };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        const total_sum = event.target.value * this.state.price;
        const accruad = (total_sum * this.state.rate) / 100;
        this.setState({ total_sum: total_sum, accruad: accruad });

    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data });
        });
        this.rows();
    }
    rows = () => {
        axios.post(DOMEN_SERVER + MANEGMENT_MERCHANDIS_ROW).then(res => {
            this.setState({ rows: res.data });
        });
    }
    serviciesPrice = (id) => {
        axios.post(DOMEN_SERVER + MANEGMENT_SERVICE_PRICE, { category_id: 6, organization_id: id }).then(res => {
            this.setState({ servicies: res.data });
        });
    }
    orgChange = (id) => {
        this.setState({ organization_id: id.value });
        this.serviciesPrice(id.value);
    }
    servChange = (id) => {
        const total_sum = this.state.service_count * id.price;
        const accruad = ((this.state.service_count * id.price) * id.rate) / 100;
        this.setState({ service_id: id.value, rate: id.rate, price: id.price, total_sum: total_sum, accruad: accruad });
    }
    seveMerchage = () => {

        if (this.state.organization_id > 0 && this.state.service_id && this.state.service_count > 0) {
            this.setState({ spiner: <Spiner /> });
            axios.post(DOMEN_SERVER + MANEGMENT_MERCHANDIS_CREATE, {
                email: this.state.email,
                organization_id: this.state.organization_id,
                service_id: this.state.service_id, service_count: this.state.service_count, accrued: this.state.accruad, price: this.state.price, rate: this.state.rate
            }).then(res => {
                this.setState({ spiner: '', rows: res.data });
            });
        } else {
            alert('Укажите значения')
        }
    }
    handleClose = () => {
        this.setState({ modal: '' });
    }
    price = () => {
        const modal = <Modal show={true} fullscreen={true} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Счета</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PriceList />
            </Modal.Body>
        </Modal>
        this.setState({ modal: modal })
    }
    render() {
        return (<>
            <div className="w-100">
                {this.state.modal}
                <div className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 ms-5 mb-5 ">
                    <div > <Button variant="link" onClick={this.price}>Прайс-лист </Button> <h6>Продажа расходников </h6> </div>
                    <Select className="w-100 m-2" placeholder="Организация" options={this.state.orgList} onChange={this.orgChange} />
                    <Select className="w-100 m-2" placeholder="Расходник" options={this.state.servicies} onChange={this.servChange} />
                    <Form.Control type="number" className="w-100 m-1" name="service_count" value={this.state.service_count} placeholder="Количество" onChange={this.inputChange} />
                    <div className="m-2">Цена продажи: {this.state.price} Сумма: {this.state.total_sum} Бонус: {this.state.rate}% К начислению: {this.state.accruad} </div>
                    <Button variant="primary" className=" m-1" size="sm" onClick={this.seveMerchage}>Сохранить</Button>

                </div>
                <div className="ms-5 mt-5">
                    <Box
                        sx={{
                            width: '90%',
                        }}
                    >
                        <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                </div>
            </div>

        </>);
    }
}
export class SmenaInsert extends React.Component {
    constructor(props) {
        super(props);
        const col = [
            { field: 'name', headerName: 'Имя', width: 150, headerAlign: 'center', },
            { field: 'email', headerName: 'Email', width: 150, headerAlign: 'center', },
            { field: 'grafik', headerName: 'График', width: 140, align: 'center', headerAlign: 'center', },
            { field: 'oklad', headerName: 'Оклад', width: 140, headerAlign: 'center', align: 'right', },
            { field: 'accrued', headerName: 'Начисление', width: 140, headerAlign: 'center', align: 'right', valueGetter: (params) => (params.row.oklad * params.row.stavka) / 100 },
            { field: 'stavka', headerName: 'Ставка %', width: 140, headerAlign: 'center', align: 'right', type: 'singleSelect', editable: true, valueOptions: [150, 140, 130, 120, 110, 100, 90, 80, 30, 70, 60, 50] },
            {
                field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                    const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{
                                    color: 'primary.main',
                                }}
                                onClick={this.handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={this.handleCancelClick(id)}
                                color="inherit"
                            />,
                        ];
                    }
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={this.handleEditClick(id)}
                            color="inherit"
                        />,

                    ];
                },

            },
        ];
        this.state = { email: '', rows: [], rowModesModel: {}, rowSelectionModel: [], setRowModesModel: {}, col: col };
    }
    //MANEGMENT_SMENA
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + MANEGMENT_SMENA).then(res => {
            this.setState({ rows: res.data });
        })
    }
    push = () => {
        //this.setState({ total_sum: total_sum });
        const rows = this.state.rowSelectionModel;
        let data = [];
        rows.map(val => {
            const row_id = this.state.rows.filter((row) => row.id === val);
            data.push([row_id[0].id, row_id[0].oklad, row_id[0].stavka]);
        });
        axios.post(DOMEN_SERVER + MANEGMENT_SMENA_INSERT, { email: this.state.email, personals: data }).then(res => {
            this.props.rows_up(res.data);
        });
    }//
    CustomToolbar = (props) => {
        let save;
        if (this.state.rowSelectionModel.length > 0) {
            save = <Button variant="outline-primary" onClick={this.push}>Сохранить</Button>;
        }
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Smena' + this.state.counterparty_name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                {save}
            </GridToolbarContainer>
        );
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        let index;
        this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        let rows = Object.assign([], this.state.rows);
        rows.map((row, i) => {
            if (row.id === newRow.id) {
                index = i;
            }
        });
        rows[index] = newRow;
        this.setState({ rows: rows });
        return updatedRow;
    }
    handleEditClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    };
    handleSaveClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => () => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }

    };
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ setRowModesModel: newRowModesModel });
    };
    selForeach = (newRowSelectionModel) => {
        this.setState({ rowSelectionModel: newRowSelectionModel });
    }
    render() {
        return (<>
            <Box
                sx={{
                    width: '90%',
                }}
            >
                <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                    slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    editMode="row"
                    processRowUpdate={this.processRowUpdate}
                    onProcessRowUpdateError={this.handleProcessRowUpdateError}
                    rowModesModel={this.state.rowModesModel}
                    onRowModesModelChange={this.handleRowModesModelChange}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        this.selForeach(newRowSelectionModel);
                    }}
                    rowSelectionModel={this.state.rowSelectionModel}
                />
            </Box>
        </>);
    }
}
export class Smena extends React.Component {
    constructor(props) {
        super(props);
        const col = [
            { field: 'created_at', headerName: 'Дата', width: 150, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
            { field: 'personal', headerName: 'Сотрудник', width: 150, headerAlign: 'center', },
            { field: 'email', headerName: 'Email', width: 150, headerAlign: 'center', },
            { field: 'grafik', headerName: 'График', width: 140, align: 'center', headerAlign: 'center', },
            { field: 'oklad', headerName: 'Оклад', width: 140, headerAlign: 'center', align: 'right', },
            { field: 'accrued', headerName: 'Начисление', width: 140, headerAlign: 'center', align: 'right', valueGetter: (params) => (params.row.oklad * params.row.stavka) / 100 },
            { field: 'stavka', headerName: 'Ставка %', width: 140, headerAlign: 'center', align: 'right', },
            { field: 'user', headerName: 'Добавил', width: 140, headerAlign: 'center', align: 'center', },

        ];
        this.state = { email: '', rows: [], col: col, modal: '' };
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        this.rows();
    }
    rows = () => {
        axios.post(DOMEN_SERVER + MANEGMENT_SMENA_TOP).then(res => {
            this.setState({ rows: res.data });
        })
    }
    closeModal = () => {
        this.setState({ modal: '' });
        this.rows();
    }
    rows_up = (rows) => {
        this.setState({ rows: rows, modal: '' });
    }
    push = () => {
        const modal = <Modal show={true} fullscreen={true} onHide={this.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Явка на смене</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SmenaInsert closeModal={this.closeModal} rows_up={this.rows_up} />
            </Modal.Body>
        </Modal>;
        this.setState({ modal: modal });
    }
    CustomToolbar = (props) => {

        return (
            <GridToolbarContainer>
                <div className="w-100">Явка на сменах - последние 100 записей</div>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Smena' + this.state.counterparty_name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                <Button variant="primary" className="ms-2" onClick={this.push}>Добавить</Button>
            </GridToolbarContainer>
        );
    }

    render() {
        return (<>
            {this.state.modal}

            <Box
                sx={{
                    width: '90%',
                }}
            >
                <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                    slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </>);
    }
}