import React, { useState } from 'react';
import axios from 'axios';
import validator from 'validator';
import { DOMEN_SERVER, DOMEN_SITE, AUTH_LOGIN, AUTH_REGISTER, AUTH_CREATE_RECOVERY, AUTH_RECOVERY_TOKEN } from '../Config/Base';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { Spiner } from './Accounting';
function Register() {
    const [register, setRegister] = useState(() => {
        return {
            username: "",
            email: "",
            password: "",
            password2: "",
            spiner:"",
        }
    });

    const changeInputRegister = event => {
        event.persist()
        setRegister(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })
    }


    const submitChackin = event => {
        event.preventDefault();
        if (!validator.isEmail(register.email)) {
            alert("Вы не ввели email")
        } else if (register.password !== register.password2) {
            alert("Неправильно повторили пароль")
        } else if (!validator.isStrongPassword(register.password, { minSymbols: 0 })) {
            alert("Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов")
        } else {
           setRegister({spiner:<Spiner/>});
            axios.post(DOMEN_SERVER + AUTH_REGISTER, {
                name: register.username,
                email: register.email,
                password: register.password,
            }).then(res => {
                if (res.data.status === true) {
                    localStorage.setItem('user', JSON.stringify({
                        email: res.data.email,
                        name: register.username,
                        token: res.data.token,
                        level: res.data.level

                    }));
                    window.location.href = DOMEN_SITE + "/";
                } else {
                    setRegister({spiner:""});
                    alert("Пользователь с таким адресом электронной почты уже есть");
                }
            }).catch(() => {
                setRegister({spiner:""});
                alert("Произошла ошибка на сервере")
            })
        }
    }
    return (
        <div className="form">
            {register.spiner}
            <h2>Регистрация</h2>
            <Form onSubmit={submitChackin}>
                <FloatingLabel controlId="floatingUsername" label="Ваше имя" className="mb-2" >
                    <Form.Control type="username" name="username" value={register.usernamr} onChange={changeInputRegister} placeholder="Name" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingEmail" label="Email" className="mb-2" >
                    <Form.Control type="email" name="email" value={register.email} onChange={changeInputRegister} placeholder="Email" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Пароль" className="mb-2" >
                    <Form.Control type="password" name="password" value={register.password} onChange={changeInputRegister} placeholder="Password" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword2" label="Повторите пароль" className="mb-2" >
                    <Form.Control type="password" name="password2" value={register.password2} onChange={changeInputRegister} placeholder="Repeat password" />
                </FloatingLabel>
                <Button variant="primary" type="submit" > Register </Button>
            </Form>
        </div>
    )
}

function Login() {
    const [login, setLogin] = useState(() => {
        return {
            email: "",
            password: "",
            spiner:"",

        }
    });

    const changeInputLogin = event => {
        event.persist()
        setLogin(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })
    }


    const submitChackin = event => {
        event.preventDefault();
            setLogin({spiner:<Spiner/>});
            axios.post(DOMEN_SERVER + AUTH_LOGIN, {
                email: login.email,
                password: login.password,
            }).then(res => {
                if (res.data.status === true) {
                    localStorage.setItem('user', JSON.stringify({
                        email: res.data.email,
                        name: res.data.name,
                        token: res.data.token,
                        level: res.data.level
                    }))
                    window.location.href = DOMEN_SITE + "/"

                } else {
                    setLogin({spiner:""});
                    alert("Неверный логин или пароль")
                }
            }).catch(() => {
                setLogin({spiner:""});
                alert("Произошла ошибка на сервере")
            })
    }
    return (
        <div>
            {login.spiner}
            <h2>Авторизация</h2>
            <Form onSubmit={submitChackin}>
                <FloatingLabel controlId="floatingEmail" label="Email" className="mb-2" >
                    <Form.Control type="email" name="email" value={login.email} onChange={changeInputLogin} placeholder="Email" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Пароль" className="mb-2" >
                    <Form.Control type="password" name="password" value={login.password} onChange={changeInputLogin} placeholder="Password" />
                </FloatingLabel>
                <a href='/recovery'>Забыли пароль?</a>   <Button variant="primary" type="submit" >Войти</Button>
            </Form>
        </div>
    )
}

export class Recovery extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', disabled: "", hidden: "hidden" };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.name]: [event.target.value] });
    }
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.email === '') {
            alert("Поле не должнобыть пустым");
        } else {
            var email = this.state.email[0];
            if (!validator.isEmail(email)) {
                alert('Неверный формат email');
            } else {
                this.setState({ disabled: 'disabled' });
                this.setState({ hidden: '' });
                axios.post(DOMEN_SERVER + AUTH_CREATE_RECOVERY, {
                    email: email, site: DOMEN_SITE
                }).then(res => {
                    if (res.data.status === true) {
                        alert(res.data.message)
                        localStorage.setItem('recovery', JSON.stringify({ email: email }))
                        window.location.href = DOMEN_SITE + "/recoverytoken"

                    } else {
                        alert(res.data.message)
                        this.setState({ disabled: '' });
                        this.setState({ hidden: 'hidden' });
                    }
                }).catch(() => {
                    this.setState({ disabled: '' });
                    this.setState({ hidden: 'hidden' });
                    alert("Произошла ошибка на сервере")
                })
            }
        }
    }

    render() {
        return (
            <>
                <h2>Сброс пароля</h2>
                <Form onSubmit={this.handleSubmit}>
                    <FloatingLabel controlId="floatingEmail" label="Email" className="mb-2" >
                        <Form.Control type="email" name="email" disabled={this.state.disabled} value={this.state.email} onChange={this.handleChange} placeholder="Email" />
                    </FloatingLabel>
                    <Button variant="primary" type="submit" disabled={this.state.disabled}><span className=" spinner-border spinner-border-sm" hidden={this.state.hidden} role="status" aria-hidden="true"></span>Продолжить</Button>
                </Form>
            </>
        );
    }
}

export class RecoveryToken extends React.Component {
    locale() {
        if (localStorage.getItem('recovery') !== null) {
            const recovery = JSON.parse(localStorage.getItem('recovery'));
            return recovery.email;
        };

        window.location.href = DOMEN_SITE + "/recovery";
    }

    constructor(props) {
        super(props);
        this.state = { email: this.locale(), recovery_token: '', newpassword: '', newpassword2: '', disabled: "", hidden: "hidden" };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.name]: [event.target.value] });
    }
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.email === '' || this.state.recovery_token === '' || this.state.newpassword === '' || this.state.newpassword2 === '') {
            alert("Поля не должны быть пустыми");
        } else {
            var email = this.state.email;
            var recovery_token = this.state.recovery_token[0];
            var newpassword = this.state.newpassword[0];
            var newpassword2 = this.state.newpassword2[0];
            if (!validator.isEmail(email)) {
                alert('Неверный формат email ' + email);
            } else if (!validator.isStrongPassword(newpassword, { minSymbols: 0 })) {
                alert("Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов")
            } else if (newpassword !== newpassword2) {
                alert('Неверный повтор пароля');
            } else {
                this.setState({ disabled: 'disabled' });
                this.setState({ hidden: '' });
                axios.post(DOMEN_SERVER + AUTH_RECOVERY_TOKEN, {
                    email: email, site: DOMEN_SITE, recovery_token: recovery_token, password: newpassword
                }).then(res => {
                    if (res.data.status === true) {
                        alert(res.data.message);
                        localStorage.clear();
                        window.location.href = DOMEN_SITE + "/auth";

                    } else {
                        alert(res.data.message)
                        this.setState({ disabled: '' });
                        this.setState({ hidden: 'hidden' });
                    }
                }).catch(() => {
                    this.setState({ disabled: '' });
                    this.setState({ hidden: 'hidden' });
                    alert("Произошла ошибка на сервере")
                })
            }
        }
    }

    render() {
        this.locale();
        return (
            <>
                <h2>Сброс пароля</h2>
                <p>На {this.state.email}, отправлен код сброса</p>
                <Form onSubmit={this.handleSubmit}>
                    <FloatingLabel controlId="floatingEmail" label="Код сброса" className="mb-2" >
                        <Form.Control type="nubmber" name="recovery_token" disabled={this.state.disabled} value={this.state.recovery_token} onChange={this.handleChange} placeholder="recovery_token" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Новый пароль" className="mb-2" >
                        <Form.Control type="password" name="newpassword" disabled={this.state.disabled} value={this.state.newpassword} onChange={this.handleChange} placeholder="Новый пароль" />
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Повторите пароль" className="mb-2" >
                        <Form.Control type="password" name="newpassword2" disabled={this.state.disabled} value={this.state.newpassword2} onChange={this.handleChange} placeholder="Повторите пароль" />
                    </FloatingLabel>
                    <Button variant="primary" type="submit" disabled={this.state.disabled}><span className=" spinner-border spinner-border-sm" hidden={this.state.hidden} role="status" aria-hidden="true"></span>Продолжить</Button>
                </Form>
            </>
        );
    }
}

export const SessionEndend=()=>{
    localStorage.clear();
     window.location.href = DOMEN_SITE + "/auth";
    alert('Время вашего сеанса истекло!');
   
}
const Reg = <Register />;
const Log = <Login />;
export { Reg, Log, };