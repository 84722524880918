import React from 'react';
import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Reg,Log,Recovery,RecoveryToken} from './Components/Auth';
import { NotFound, Hello } from './Components/Home';
import { Home } from './Components/Home';
import { MySalary, MyWorking, } from './Components/Cabinet';
import MyProfile from './Components/Cabinet';
import { Users, Service, Cash} from './Components/Admin';
import { Application, Clients, Merchandise, Smena, Subject } from './Components/Manegment';
import { Article, ActiveApplications, СompletedApplications } from './Components/Tasks';
import { Boxes,Razbivka,Transport,Bagage} from './Components/Logistic';
import './App.css';
import { Entries,Accounts,Salary,Cashbox, Agreement, Reports } from './Components/Accounting';


const App= ()=> {

  return(  
  <Router>
    <Routes>
    <Route path="/" element={Home } >
      <Route index element={<Hello /> } />
      <Route path="registr" element={Reg} />
      <Route path="auth" element={Log} />
      <Route path="mysalary" element={<MySalary/> } />
      <Route path="myworking" element={<MyWorking/> } />
      <Route path="myprofile" element= {<MyProfile />}/>
      <Route path="users" element= {<Users />}/>
      <Route path="subject" element= {<Subject />}/>
      <Route path="articlеs" element= {<Article />}/>
      <Route path="applications" element= {<Application />}/>
      <Route path="services" element= {<Service />}/>
      <Route path="recovery" element= {<Recovery />}/>
      <Route path="recoverytoken" element= {<RecoveryToken />}/>
      <Route path="сlients" element= {<Clients />}/>
      <Route path="active_applications" element={<ActiveApplications/>} />
      <Route path="completed_applications" element={<СompletedApplications/>} />
      <Route path="boxes" element={<Boxes/>} />
      <Route path="razbivka" element={<Razbivka/>} />
      <Route path="transport" element={<Transport/>} />
      <Route path="bagage" element={<Bagage/>} />
      <Route path="accounting-entries" element={<Entries/>} />
      <Route path="accounting-accounts" element={<Accounts/>} />
      <Route path="accounting-salary" element={<Salary/>} />
      <Route path="accounting-cashbox" element={<Cashbox/>} />
      <Route path="accounting-agreement" element={<Agreement/>} />
      <Route path="accounting-reports" element={<Reports/>} />
      <Route path="merchandise" element={<Merchandise/>} />
      <Route path="smena" element={<Smena/>} />
      <Route path="cash" element={<Cash/>} />
      <Route path='*' element={<NotFound />}/>
      </Route>
    </Routes>
  </Router>);

}
export default App;