import React from "react";
import axios from "axios";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import validator from 'validator';
import { DOMEN_SERVER, DOMEN_SITE, CABINET_MYPROFILE, AUTH_NEWPASSWORD, CABINET_MYWORK, TASK_ORG_LIST, CABINET_MY_SALARY_DATA } from "../Config/Base";
import { Accordion, Card } from "react-bootstrap";
import { PersonalListId, Spiner } from "./Accounting";
import { SessionEndend } from "./Auth";


export class MySalary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { history: [], balance: 0, accrued: 0, spiner: '' }
  }
  componentDidMount() {


    const store = JSON.parse(localStorage.getItem('user'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response.statusText == "Unauthorized") {
        return SessionEndend();
      }
      return Promise.reject(error);
    });
    this.setState({ spiner: <Spiner /> });
    // this.setState({email:,salary:<PersonalListId personal={this.personal} email={store.email}/>});
    axios.post(DOMEN_SERVER + CABINET_MY_SALARY_DATA, { email: store.email }).then(res => {
      this.setState({ balance: res.data.balance, accrued: res.data.accrued, history: res.data.history, spiner: '' });

    }).finally(()=>{
      this.setState({spiner: ''});
    });
  }

  render() {
    return (<>
      {this.state.spiner}
      <div className="w-100 text-center p-5"><h3>Заработок</h3></div>
      <div >
        <div ><b>Текущий баланс: <span className="text-success">{this.state.balance} руб.</span></b></div>
        <div><b>Ожидаем начисление: <span className="text-secondary">{this.state.accrued} руб.</span></b></div>
      </div>
      

      <div className="m-3 col-11 col-sm-9 col-md-6 col-lg-5 col-xl-4 "><div className="w-100 text-center border p-1"><b className="text-secondary">История</b></div>
        <div className="scroll-my ">{this.state.history.map((val,i)=>(<div key={i} className={val.nn===1?'accrued':val.nn===2?'held':'paid'}>
          <div className="d-flex"><div className="w-100"><small>№ {val.id} от {val.date}</small></div>
          <div className="w-100 text-center"><b><i>{val.name}</i></b></div>
          <div className="w-100 text-end m-1"><small><i>{val.user}</i></small></div></div>         
          <div className="d-flex">
            <div className="w-100 text-center"><small>Сумма</small></div> 
            <div className="w-100 text-center"><small>Баланс</small></div>
            <div className="w-100 text-center"><small>Сообщение</small></div>
            </div> 
          <div className="d-flex">
            <div className="w-100 text-center"><small><b>{val.sum} руб.</b></small></div> 
            <div className="w-100 text-center"><small><b>{val.balance}  руб.</b></small></div>
            <div className="w-100 text-center "><small><i>{val.description}</i></small></div>
            </div> 
          
          
          
          </div>))}</div>
       </div>
    </>);
  }
}


export class MyWorking extends React.Component {
  constructor(props) {
    super(props);
    const getd = new Date();
    getd.setDate(getd.getDate() - 10);
    const getdd = getd.toJSON().slice(0, 10);
    const endd = new Date().toJSON().slice(0, 10);


    this.state = { email: '', GetCreated: getdd, EndCreated: endd, rows: [], title: [], zp: 0, spiner: '' }
    this.handleChange = this.handleChange.bind(this);


  }
  handleChange(event) {

    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {

    const store = JSON.parse(localStorage.getItem('user'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response.statusText == "Unauthorized") {
        return SessionEndend();
      }
      return Promise.reject(error);
    });
    this.setState({ email: store.email });


  }


  getTable = () => {
    if (this.state.GetCreated < this.state.EndCreated) {
      this.setState({ zp: 0, spiner: <Spiner /> })
      axios.post(DOMEN_SERVER + CABINET_MYWORK, { email: this.state.email, GetCreated: this.state.GetCreated, EndCreated: this.state.EndCreated }).then(res => {

        this.setState({ rows: res.data, spiner: '' });
        this.state.title.length = 0;
        let akk = [];
        res.data.map((val, i) => {
          if (akk.filter(e => e.application_id === val.application_id).length === 0) {
            let summa = 0;
            let body = [];
            res.data.map(a => {
              if (val.application_id === a.application_id) {
                summa = summa + parseFloat(a.sum);
                body.push({ service: a.service, rate: a.rate, num_me: a.num_me, sum: a.sum });
              }
            });
            akk.push({ application_id: val.application_id, zp: summa });
            this.state.title.push(
              <Accordion.Item key={i} eventKey={i} >
                <Accordion.Header ><Card className="w-100 border-0 m-0 ">
                  <Row className="text-center m-0 p-0"><Col >{val.update_at}</Col><Col><pre>{val.application_id}</pre></Col><Col><pre>{val.organization}</pre></Col><Col><pre>{summa}</pre></Col><Col><pre>{val.salary_id}</pre></Col></Row>
                </Card>
                </Accordion.Header>
                <Accordion.Body>
                  <Table className="w-100 border-0 m-0 table-sm">
                    <thead><tr className="text-center  border-bottom"><th><pre>Услуга</pre></th><th><pre>Ставка</pre></th><th><pre>Операций</pre></th><th><pre>Сумма</pre></th></tr></thead><tbody>
                      {body.map((s, is) => (
                        <tr className="border-bottom" key={is}><td className="text-left"><pre>{s.service}</pre></td><td className="text-center "><pre>{s.rate}</pre></td><td className="text-center "><pre>{s.num_me}</pre></td><td className="text-center "><pre>{s.sum}</pre></td></tr>
                      ))}
                    </tbody>
                    <tfoot>
                    </tfoot>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            );

          }

        });
        let zp = 0;
        akk.map(z => (zp = zp + z.zp));
        this.setState({ zp: zp });
      });
    } else {
      alert('Не верно указан интервал');
    }
  }
  render() {
    return (<>
      {this.state.spiner}
      <div>
        <h1>Выработка</h1>
        <div className=" col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3">
          <div>
            <Form onSubmit={this.handleSubmit}>
              <div className="d-flex ">
                <div className="col m-2">
                  <Form.Label>Начало</Form.Label>
                  <Form.Control type="date" name="GetCreated" value={this.state.GetCreated} onChange={this.handleChange} placeholder="Начало" />
                </div>
                <div className="col m-2">
                  <Form.Label>Конец</Form.Label>
                  <Form.Control type="date" name="EndCreated" value={this.state.EndCreated} onChange={this.handleChange} placeholder="Конец" />
                  <Button className="mt-2" variant="primary" onClick={this.getTable} type="button">Загрузить</Button>
                </div>
              </div>
            </Form>
          </div>

        </div>
      </div><div className="col-11">
        <div><pre>За указаный период заработано <b>{this.state.zp}</b></pre></div>
        <Row className="text-center m-0 p-0"><Col>Дата</Col><Col><pre>Заявка</pre></Col><Col><pre>Организация</pre></Col><Col><pre>Заработок</pre></Col> <Col><pre>Зарплата №</pre></Col></Row>
        <Accordion >
          {this.state.title.map(val => (val))}
        </Accordion>
      </div>


    </>);
  }
}

export default class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: [],
      create_at: [],
      password: '',
      newpassword: '',
      newpassword2: '',
      spin: false,
      disabled: '',
      hidden: 'hidden',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ [event.target.name]: [event.target.value] });
  }


  handleSubmit(event) {
    var password = this.state.password[0];
    var newpassword = this.state.newpassword[0];
    var newpassword2 = this.state.newpassword2[0];
    event.preventDefault();
    if (this.state.password === '' || this.state.newpassword === '' || this.state.newpassword2 === '') {
      alert("Заполните поля")
    } else if (newpassword !== newpassword2) {
      alert("Неправильно повторили пароль")
    } else if (!validator.isStrongPassword(newpassword, { minSymbols: 0 })) {
      alert("Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов")
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      this.setState({ disabled: 'disabled' });
      this.setState({ hidden: '' });
      axios.post(DOMEN_SERVER + AUTH_NEWPASSWORD, {
        email: user.email,
        password: password,
        newpassword: newpassword,
      }).then(res => {
        if (res.data.status === true) {
          alert(res.data.message)
          window.location.href = DOMEN_SITE + "/"

        } else {
          alert(res.data.message)
          this.setState({ disabled: '' });
          this.setState({ hidden: 'hidden' });
        }
      }).catch(() => {
        this.setState({ disabled: '' });
        this.setState({ hidden: 'hidden' });
        alert("Произошла ошибка на сервере")
      })
    }
  }

  componentDidMount() {
    const store = JSON.parse(localStorage.getItem('user'));
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response.statusText == "Unauthorized") {
        return SessionEndend();
      }
      return Promise.reject(error);
    });
    axios.post(DOMEN_SERVER + CABINET_MYPROFILE, { email: store.email }).then(res => {
      const profile = res.data;
      const create_at = new Date(res.data.created_at)
      this.setState({ profile });
      this.setState({ create_at });
    })

  }
  render() {
    return (
      <Container>
        <Row className="d-flex justify-content-center m-5">
          <Row > <Col className="d-flex justify-content-end">Имя:</Col> <Col className="d-flex justify-content-rigth"><b>{this.state.profile.name}</b></Col></Row>
          <Row > <Col className="d-flex justify-content-end">Email: </Col> <Col className="d-flex justify-content-rigth"><b>{this.state.profile.email}</b></Col></Row>
          <Row> <Col className="d-flex justify-content-end">Доступ: </Col> <Col className="d-flex justify-content-rigth"><b>{this.state.profile.level} </b></Col></Row>
          <Row> <Col className="d-flex justify-content-end">Регистрация: </Col ><Col className="d-flex justify-content-rigth"><b>{this.state.create_at.toLocaleString()}</b></Col></Row>
        </Row>
        <Row className="  ">
          <Container className="d-flex justify-content-center">
            <div >
              <h2 >Изменить пароль</h2>
              <Form onSubmit={this.handleSubmit} >
                <FloatingLabel controlId="floatingPassword" label="Текущий пароль" className="mb-2" >
                  <Form.Control type="password" name="password" disabled={this.state.disabled} value={this.state.value} onChange={this.handleChange} placeholder="Текущий пароль" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Новый пароль" className="mb-2" >
                  <Form.Control type="password" name="newpassword" disabled={this.state.disabled} value={this.state.value} onChange={this.handleChange} placeholder="Новый пароль" />
                </FloatingLabel>
                <FloatingLabel controlId="floatingPassword" label="Повторите пароль" className="mb-2" >
                  <Form.Control type="password" name="newpassword2" disabled={this.state.disabled} value={this.state.value} onChange={this.handleChange} placeholder="Повторите пароль" />
                </FloatingLabel>
                <Button variant="primary" type="submit" disabled={this.state.disabled}><span className=" spinner-border spinner-border-sm" hidden={this.state.hidden} role="status" aria-hidden="true"></span>Изменить</Button>

              </Form>
            </div>
          </Container>
        </Row>
      </Container>
    );
  }
}
