import React from 'react';
import axios from 'axios';
import { DOMEN_SERVER, DOMEN_SITE, AUTH_LOGOUT } from '../Config/Base';
import logo from '../logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button } from 'react-bootstrap';


function NavBar() {

  const navMass = [];

  if (localStorage.getItem('user') !== null) {

    const user = JSON.parse(localStorage.getItem('user'));

    const LogoutFn = () => {
      axios.post(DOMEN_SERVER + AUTH_LOGOUT, {
        email: user.email,
        tokenId: user.token
      }).then(res => {
        localStorage.clear();
        window.location.href = DOMEN_SITE + "/"
      }).catch(()=>{
        localStorage.clear();
      });

    }

    const tasks = <NavDropdown title="Производство" key="mdZ"><NavDropdown.Item href="active_applications">Активные</NavDropdown.Item><NavDropdown.Item href="completed_applications">Завершенные</NavDropdown.Item><NavDropdown.Item href="articlеs">Артикулы</NavDropdown.Item></NavDropdown>;
    const stock = <NavDropdown title="Логистика" key="mdNL"><NavDropdown.Item href="boxes">Короба</NavDropdown.Item>
    <NavDropdown.Item href="razbivka">Разбивка</NavDropdown.Item>
    <NavDropdown.Item href="transport">Транспортировка</NavDropdown.Item>
    <NavDropdown.Item href="bagage">Багаж</NavDropdown.Item>
    </NavDropdown>;
    const management = <NavDropdown title="Менеджмент" key="mdM"><NavDropdown.Item href="/applications">Заявки</NavDropdown.Item>
    <NavDropdown.Item href="merchandise">Продажа</NavDropdown.Item><NavDropdown.Item href="/сlients">Клиенты</NavDropdown.Item>
    <NavDropdown.Item href="/subject">Предметы</NavDropdown.Item><NavDropdown.Item href="/smena">Смена</NavDropdown.Item></NavDropdown>;
    const administration = <NavDropdown title="Администрация" key="mdA"><NavDropdown.Item href="/users">Роли</NavDropdown.Item>
    <NavDropdown.Item href="/services">Сервисы</NavDropdown.Item>
    <NavDropdown.Item href="/cash">Кассы</NavDropdown.Item>
    
    </NavDropdown>;
    const bookkeeping = <NavDropdown  key="mdBK" title="Бухгалтерия"><NavDropdown.Item href="accounting-entries">Проводки</NavDropdown.Item><NavDropdown.Item href="accounting-accounts">Счета</NavDropdown.Item><NavDropdown.Item href="accounting-salary">Зарплата</NavDropdown.Item>
    <NavDropdown.Item href="accounting-cashbox">Касса</NavDropdown.Item><NavDropdown.Item href="accounting-agreement">Договора</NavDropdown.Item>
    <NavDropdown.Item href="accounting-reports">Очеты</NavDropdown.Item></NavDropdown>;
    const cabinet = <NavDropdown title={user.email} key="mdC"><NavDropdown.Item href="/myprofile">Профиль</NavDropdown.Item><NavDropdown.Item href="/myworking">Выработка</NavDropdown.Item><NavDropdown.Item href="/mysalary">Зарплата</NavDropdown.Item><NavDropdown.Item onClick={LogoutFn} >Выйти</NavDropdown.Item></NavDropdown>;

    const fetchData = async () => {
      const level = await user.level;
      if (level === 0) { navMass.push( cabinet); }
      if (level === 1) { navMass.push(tasks, cabinet); }
      if (level === 2) { navMass.push(tasks, stock, cabinet); }
      if (level === 3) { navMass.push(tasks, stock, management, cabinet); }
      if (level === 4) { navMass.push(tasks, stock, management, bookkeeping, cabinet); }
      if (level === 5) { navMass.push(tasks, stock, management, administration, bookkeeping, cabinet); }
    }

    fetchData();

  } else {

    const Authorization = <NavDropdown title="Авторизация" key="mdA"> <NavDropdown.Item href="/auth">Войти</NavDropdown.Item> <NavDropdown.Item href="/registr"> Регистрация </NavDropdown.Item></NavDropdown>;

    navMass.push(Authorization);

  }


  return <>
    {['md'].map((expand) => (
      <Navbar key={expand} bg="light" expand={expand} className="mb-3">
        <Container fluid >
          <Navbar.Brand href="/"><img className='App-logo' src={logo} alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img className='App-logo' src={logo} alt="logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {navMass}

              </Nav>

            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    ))}
  </>
}

function Futer() {
  let today = new Date();
  let year = today.getFullYear();
  return (
    <Nav className="justify-content-end">
      <Container >
        <Row>
          <Col> Copyright © 2020 - {year} Elida company.</Col>
        </Row>
      </Container>
    </Nav>
  );
}
//My Pagginate
// props values current_page,last_page
// props functions toPage
export class MyPagginate extends React.Component{
  constructor(props){
    super(props);
  }
  render(){
    return(<div className='d-flex'>
      <Button  variant="outline-dark" className="ms-1" size="sm" onClick={() => this.props.toPage(1)} >&laquo;</Button>
      {(this.props.current_page - 2)>0 ? <Button  className="ms-1" size="sm" variant="outline-dark" onClick={() => this.props.toPage(this.props.current_page - 2)} >{this.props.current_page - 2}</Button>:''}
      {(this.props.current_page - 1)>0 ? <Button className="ms-1" size="sm" variant="outline-dark" onClick={() => this.props.toPage(this.props.current_page - 1)} >{this.props.current_page - 1}</Button>:''}
      <Button  disabled size="sm" className="ms-1" variant="light">{this.props.current_page}</Button>
      {(this.props.current_page + 1) <= this.props.last_page ? <Button  className="ms-1" size="sm" variant="outline-dark" onClick={() => this.props.toPage(this.props.current_page + 1)} >{this.props.current_page + 1}</Button>:''}
      {(this.props.current_page + 2) <= this.props.last_page ? <Button  className="ms-1" size="sm" variant="outline-dark" onClick={() => this.props.toPage(this.props.current_page + 2)} >{this.props.current_page + 2}</Button>:''}
      <Button  variant="outline-dark" className="ms-1" size="sm" onClick={() => this.props.toPage(this.props.last_page)} >&raquo;</Button>
    </div>);
  }
}


export { Futer, NavBar }