import React from "react";
import axios from "axios";
import Select from "react-select";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from "@mui/icons-material/Delete";
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
    DOMEN_SERVER, TASK_ORG_LIST, LOGISTIC_BOX_STATUS, LOGISTIC_UP_BOX_STATUS, LOGISTIC_RAZBIVKA_BOXES, MANEGMENT_SERVICE_APP,
    LOGISTIC_SERVICE_TRANSPORT, LOGISTIC_SERVICE_TRANSPORT_ROW, LOGISTIC_UP_BOX_CELL, MANEGMENT_PRINT_SHK_BOX, LOGISTIC_LABEL_BAGGAGE, LOGISTIC_TIE_BAGGAGE, LOGISTIC_ALL_BAGGAGE, LOGISTIC_UP_ALL_BAGGAGE, LOGISTIC_DELETE_BAGGAGE, LOGISTIC_SEARCH_BOX, LOGISTIC_STATUS_BOX
} from "../Config/Base";
import { DataGrid, GridToolbarContainer, GridToolbarExport, ruRU, GridRowModes, GridActionsCellItem, } from "@mui/x-data-grid";
import { darken, lighten, styled } from '@mui/material/styles';
import { Button, Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Spiner } from "./Accounting";
import { SessionEndend } from "./Auth";




export class Boxes extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            email: '', orgList: [], columns: [], rowSelectionModel: [], organization_id: '', spiner: '', data: [], name: '', table: [],
            rowModesModel: {}, setRowModesModel: {}, rows: [], status: ['Активный', 'Отгружено'], col: [], styleDataGrid: {}, cell: '', modal: '',
            boxName: '', searchResult: '', searchBoxId: '', stopStream: false, scanerModal: false, activeTabs: 'searchBox', historyBoxName:'',history:'',
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.name]: [event.target.value] });
    }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> })
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState(val => ({ email: store.email }));
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data });
        });
        this.columns();
        this.setState({ spiner: '' });
        this.inputRef.current.focus();
    }
    push = (event) => {
        this.saveStatuse(this.state.rowSelectionModel, event.target.value);
    }

    CustomToolbar = (props) => {
        let save;
        if (this.state.rowSelectionModel.length > 0) {
            save = <Form> <Form.Select className="m-1" size="sm" value={-1} onChange={this.push}>
                <option value={-1} disabled>Изменить статус</option>
                <option value='Активный'>Активный</option>
                <option value='Отгружено'>Отгружено</option>
            </Form.Select>
                <div className="d-flex"><Form.Control className="m-1" size="sm" name="cell" placeholder="Задать ячейку" onChange={this.handleChange}>
                </Form.Control>
                    <Button size="sm" className="m-1" variant="success" onClick={this.cell}>Сохранить</Button></div>
            </Form>;

        }
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Короба_' + this.state.name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                <div>{save}</div>
            </GridToolbarContainer>
        );
    }
    saveStatuse = (id, statuse) => {
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + LOGISTIC_UP_BOX_STATUS, { id: id, status: statuse, organization_id: this.state.organization_id }).then(res => {
            this.setState({ rowSelectionModel: [], rows: res.data, spiner: '' });
        });
    }
    cell = () => {
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + LOGISTIC_UP_BOX_CELL, { id: this.state.rowSelectionModel, cell: this.state.cell[0], organization_id: this.state.organization_id }).then(res => {
            this.setState({ rowSelectionModel: [], rows: res.data, spiner: '' });
        });
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        this.saveStatuse([updatedRow.id], updatedRow.status);
        return updatedRow;
    }
    handleEditClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    };
    getElem = () => {
        const aaaa = document.getElementById('aaaa');
        aaaa.click();
    }
    clearModal = () => {
        this.setState({ modal: '' });
    }
    handlePrintClick = (id) => () => {
        const a = this.state.rows.find((row) => row.id === id);
        window.open(DOMEN_SERVER + '/managment/shk_box?application_id=' + a.application_id + '&box_id=' + id, "_blank");

    }
    handleSaveClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => () => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }

    };
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        //console.log(newRowModesModel); не понятный
        this.setState({ setRowModesModel: newRowModesModel });
    };
    orgChange = (event) => {
        var name = event.label;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        this.setState({ rows: [], name: name, organization_id: event.value });
        axios.post(DOMEN_SERVER + LOGISTIC_BOX_STATUS, { id: event.value }).then(res => {
            this.setState({ rows: res.data.boxes, status: res.data.statuses.map(val => (val.name)) });
            this.columns();
        });

    }
    selForeach = (newRowSelectionModel) => {
        this.setState({ rowSelectionModel: newRowSelectionModel });
    }

    columns = () => {
        const col = [{ field: 'box', headerName: 'Короб', width: 150, headerAlign: 'center', },
        { field: 'status', headerName: 'Статус', width: 150, headerAlign: 'center', type: 'singleSelect', editable: true, valueOptions: this.state.status },
        { field: 'cell', headerName: 'Ячейка', width: 120, headerAlign: 'center' },
        {
            field: 'actions', headerName: 'Edit', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={this.handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<PrintIcon />}
                        label="Print"
                        className="textPrimary"
                        onClick={this.handlePrintClick(id)}
                        color="inherit"
                    />,

                ];
            },

        },
        ];

        this.setState({ col: col, });
    }
    searchCard = (x) => {
        if (x.status === true) {

            const info = x.data;
            const card = <div className="border">
                <table className="w-100">
                    <tr className={info.status_id === 1 ? 'bg-success text-white' : 'bg-danger text-white'}><td>Статус:</td><td><small><b><i>{info.status}</i></b></small></td></tr>
                    <tr><td>Короб:</td><td><small><b><i>{info.name}</i></b></small></td></tr>
                    <tr><td>Организация:</td><td><small><b><i>{info.organization}</i></b></small></td></tr>
                    <tr><td>Сформировал:</td><td><small><b><i>{info.user}</i></b></small></td></tr>
                    <tr><td>Дата:</td><td><small><b><i>{info.created_at}</i></b></small></td></tr>
                    {info.status_id === 1 ? <tr><td colSpan={2}><Button variant="danger" className="m-2" onClick={() => this.statusBox(7)}>Отгружено</Button></td></tr> :
                        <tr><td colSpan={2}><Button variant="success" className="m-2" onClick={() => this.statusBox(1)}>Активировать</Button></td></tr>}
                </table>
            </div>;
            this.setState({ searchResult: card, searchBoxId: info.id, historyBoxName:info.name, boxName: '', spiner: '' });
        } else {
            const info = x.data;
            const card = <div className="border mt-5 mb-5">
                <h6 className="text-danger mt-5 mb-5">{info}</h6>
            </div>
            this.setState({ searchResult: card, searchBoxId: '', boxName: '', spiner: '', historyBoxName:'' });
        }
        this.inputRef.current.focus();
    }
    searchBox = () => {
        if (this.state.boxName !== '') {
            this.setState({ spiner: <Spiner /> });
            axios.post(DOMEN_SERVER + LOGISTIC_SEARCH_BOX, { boxName: this.state.boxName }).then(res => {
                this.searchCard(res.data);
            });
        } else {
            alert("Укажите название короба");
        }
    }
    statusBox = (x) => {
        if (this.state.searchBoxId !== '') {
            this.setState({ spiner: <Spiner /> });
            axios.post(DOMEN_SERVER + LOGISTIC_STATUS_BOX, { id: this.state.searchBoxId, status_id: x }).then(res => {
                this.searchCard(res.data);
                var status;
                if(x === 1){
                    status="активен"
                }
                if(x === 7){
                    status="отгружен"
                }
                if(res.data.status===true){

                    const history = <><p><small>{this.state.historyBoxName+' '+status}</small></p>{this.state.history}</>;
                    this.setState({history:history})
                }
            });
        } else {
            alert("Произошла ошибка, обратитесь к администратору!");
        }
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.searchBox();
        }
    };
    scanerModalClose = () => {
        this.setState({ scanerModal: false })
    }
    scanerModalShow = () => {
        this.setState({ stopStream: false, scanerModal: true, })
    }

    render() {
        return (<>
            {this.state.modal}
            {this.state.spiner}
            <Modal show={this.state.scanerModal} fullscreen={true} onHide={this.scanerModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Сканирование</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <div className="col-11 col-sm-11 col-md-9 col-lg-7 col-xl-5">
                        <BarcodeScannerComponent
                            stopStream={this.state.stopStream}
                            onUpdate={(err, result) => {
                                if (result) this.setState({ boxName: result.text, stopStream: true, scanerModal: false });
                                else this.setState({ boxName: "Not Found" });
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <div className="col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5   ">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.activeTabs}
                    onSelect={(k) => this.setState({ activeTabs: k })}
                    className="mb-3">
                    <Tab eventKey="searchBox" title="Найти короб">
                    <div><h6>Найти короб</h6>

                    <div className="d-flex"><Form.Control ref={this.inputRef} type="text" placeholder="Номер короба" className="me-1" name="boxName" value={this.state.boxName} onKeyDown={this.handleKeyPress} onChange={this.handleChange} />
                        <Button variant="outline-secondary" className="ms-1" onClick={this.scanerModalShow}><DocumentScannerOutlinedIcon /></Button>
                        <Button variant="outline-secondary" className="ms-1" onClick={this.searchBox}>&#128270;</Button></div>
                    <div className="mt-3 mb-3 ">{this.state.searchResult}</div>
                    <div className="historyBox mt-3 mb-3">{this.state.history}</div>
                </div>
                    </Tab>
                    <Tab eventKey="listBox" title="Список коробов">
                    <div><h6>Список коробов</h6>
                    <Select className="w-100 m-1" options={this.state.orgList} onChange={this.orgChange} placeholder="Организация" />

                </div>
                {this.state.rows.length > 0 ? <div className=" col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 w-100">
                    <Box
                        sx={{
                            width: '100%',
                            '& .super-app-theme--Активный': {
                                backgroundColor: '#d3facf',
                                '&:hover': {
                                    backgroundColor: '#70f760'
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#6bd12c',
                                    '&:hover': {
                                        backgroundColor: '#4e9e1c'
                                    },
                                },
                            },
                            '& .super-app-theme--Отгружено': {
                                backgroundColor: '#f7f1ad',
                                '&:hover': {
                                    backgroundColor: '#ebe13d'
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#f0d211',
                                    '&:hover': {
                                        backgroundColor: '#f0d500'
                                    },
                                },
                            },

                        }}
                    >

                        <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}

                            editMode="row"
                            slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            processRowUpdate={this.processRowUpdate}
                            onProcessRowUpdateError={this.handleProcessRowUpdateError}
                            rowModesModel={this.state.rowModesModel}
                            onRowModesModelChange={this.handleRowModesModelChange}
                            getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                            checkboxSelection
                            disableRowSelectionOnClick
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                this.selForeach(newRowSelectionModel);
                            }}
                            rowSelectionModel={this.state.rowSelectionModel}
                        />
                    </Box>
                </div> : ''}
                    </Tab>
                </Tabs>
                
                
            </div>

        </>)

    }
}

export class Razbivka extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', orgList: [], columns: [], data: [], table: [], hidden: '', rows: [], col: [], organization: '' }
    };
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState(val => ({ email: store.email }));
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data });
        });
        this.columns();
        this.setState({ hidden: 'hidden' });

    };
    CustomToolbar = (props) => {
        const { setRows, setRowModesModel } = props;
        //<Button className="ms-2 mb-2 mt-1" size="sm" hidden={this.state.saveButton} variant="warning">save</Button>
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Разбивка_' + this.state.organization,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }
    columns = () => {
        const col = [{ field: 'application_id', headerName: '#', width: 80, headerAlign: 'center', },
        { field: 'box', headerName: 'Короб', width: 100, headerAlign: 'center', },
        { field: 'code', headerName: 'Баркод', width: 150, headerAlign: 'center', },
        { field: 'article', headerName: 'Артикул', width: 100, headerAlign: 'center', },
        { field: 'size', headerName: 'Размер', width: 80, headerAlign: 'center', },
        { field: 'num', headerName: 'Кол-во', width: 80, headerAlign: 'center', },
        { field: 'status', headerName: 'Статус', width: 100, headerAlign: 'center', },
        ];
        this.setState({ col: col, });
    }
    orgChange = (event) => {
        var organization = event.label;
        organization = organization.replaceAll(' ', '_')
        organization = organization.replaceAll('.', '_')
        this.setState({ rows: [], organization: organization });
        axios.post(DOMEN_SERVER + LOGISTIC_RAZBIVKA_BOXES, { id: event.value }).then(res => {
            this.setState({ rows: res.data });
            this.columns();
        });
    }


    render() {
        return (<>
            <div className="overlay" hidden={this.state.hidden}>
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                </div>
                <span className="m-2"> Loading...</span>
            </div>
            <div className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3   ">
                <h1>Разбивка </h1>
                <Select className="w-100 m-1" options={this.state.orgList} onChange={this.orgChange} />

            </div>
            <div className=" col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5">
                <Box
                    sx={{
                        width: '100%',
                        '& .super-app-theme--Активный': {
                            backgroundColor: '#d3facf',
                            '&:hover': {
                                backgroundColor: '#70f760'
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#6bd12c',
                                '&:hover': {
                                    backgroundColor: '#4e9e1c'
                                },
                            },
                            '& .MuiDataGrid-columnsContainer': {
                                backgroundColor: 'dark',
                            },
                        },
                        '& .super-app-theme--Отгружено': {
                            backgroundColor: '#f7f1ad',
                            '&:hover': {
                                backgroundColor: '#ebe13d'
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#f0d211',
                                '&:hover': {
                                    backgroundColor: '#f0d500'
                                },
                            },
                        },

                    }}
                >

                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}

                        slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        getRowClassName={(params) => `super-app-theme--${params.row.status}`}

                        checkboxSelection disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </>)
    }
}

export class Transport extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'created_at', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'organization', headerName: 'Компания', width: 200, headerAlign: 'center', },
        { field: 'service', headerName: 'Услуга', width: 200, headerAlign: 'center', align: 'center', },
        { field: 'service_count', headerName: 'Количество', width: 150, headerAlign: 'center', align: 'center', },
        { field: 'accrued', headerName: 'Начисление', width: 150, headerAlign: 'center', align: 'center', },
        { field: 'salary_id', headerName: 'Зарплата №', width: 150, headerAlign: 'center', align: 'center', },
        { field: 'user', headerName: 'Исполнитель', width: 150, headerAlign: 'center', align: 'center', },];
        this.state = { rows: [], col: col, email: '', orgList: [], servicies: [], radio: 0, disabled: false, organization_id: '', service_id: '', service_count: 1, rate: 0, spiner: '' }
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data });
        });
        axios.post(DOMEN_SERVER + MANEGMENT_SERVICE_APP, { category_id: 4 }).then(res => {
            this.setState({ servicies: res.data });
        });
        this.rows();
    }
    orgChange = (id) => {
        this.setState({ organization_id: id.value });
    }
    servChange = (id) => {
        this.setState({ service_id: id.value, rate: id.rate });
        if (id.value == 10 || id.value == 12) {
            this.setState({ disabled: true, service_count: 1 });
        } else {
            this.setState({ disabled: false });
        }
    }
    radioClick = (event) => {
        this.setState({ radio: event.target.id });
    }
    saveRecord = () => {
        if (this.state.organization_id > 0 && this.state.service_id > 0 && this.state.service_count > 0) {
            let accruad = 0;
            if (this.state.radio == 2) {
                accruad = this.state.rate * this.state.service_count;
            } this.setState({ spiner: <Spiner /> })
            axios.post(DOMEN_SERVER + LOGISTIC_SERVICE_TRANSPORT, { email: this.state.email, organization_id: this.state.organization_id, service_id: this.state.service_id, service_count: this.state.service_count, accrued: accruad }).then(res => {
                this.setState({ spiner: '', rows: res.data, service_count: 1 });
            });
        } else {
            alert('Укажите значения');
        }
    }
    rows = () => {
        axios.post(DOMEN_SERVER + LOGISTIC_SERVICE_TRANSPORT_ROW).then(res => {
            this.setState({ rows: res.data });
        });
    }
    render() {
        let accruad;
        let body;
        if (this.state.radio == 2) {
            accruad = <div>К начислению: {this.state.rate * this.state.service_count}</div>;
        }
        if (this.state.radio > 0) {
            body = <div>
                <Select className="w-100 m-1" placeholder="Организация" options={this.state.orgList} onChange={this.orgChange} />
                <Select className="w-100 m-1" placeholder="Услуги" options={this.state.servicies} onChange={this.servChange} />
                <Form.Control type="number" className="w-100 m-1" name="service_count" value={this.state.service_count} disabled={this.state.disabled} placeholder="Количество" onChange={this.inputChange} />
                {accruad}
                <Button variant="primary" className=" m-1" size="sm" onClick={this.saveRecord}>Сохранить</Button>
            </div>;
        }
        return (<>
            {this.state.spiner}
            <div className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3  mb-5 ">
                <h1>Транспортировка </h1>
                <div className=" text-left w-100">
                    <Form.Check className="text-start" label='Транспортной компанией' name="group1" type='radio' id={1} onClick={this.radioClick} />
                    <Form.Check className="text-start" label='Собственными силами' name="group1" type='radio' id={2} onClick={this.radioClick} />
                </div>
                {body}
            </div>
            <div className=" m-5">
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>
            </div>
        </>);
    }
}

class LabelBagage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', row: [], quantity: 0, spiner: '' };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    connect = () => {
        this.setState({ spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + LOGISTIC_LABEL_BAGGAGE, { email: this.state.email, quantity: this.state.quantity }).then(res => {
            this.setState({ row: res.data, spiner: '', quantity: 0 });
        });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState(val => ({ email: store.email }));
        this.connect();
    }
    shkBag = (bag) => {
        window.open(DOMEN_SERVER + '/managment/shk_bag?bag=' + bag, "_blank");
    }
    render() {
        return (<>
            {this.state.spiner}

            <h1>Ярлыки</h1>
            <div>
                <Form>
                    <div className="d-flex"><Form.Control className="m-1" size="sm" type="number" name="quantity" value={this.state.quantity} placeholder="Количество ярлыков" onChange={this.inputChange}>
                    </Form.Control>
                        <Button size="sm" className="m-1" variant="success" onClick={this.connect}>Добавить</Button></div>
                </Form>
                <div>
                    <div>
                        <Button size="sm" className="m-1" variant="primary" onClick={() => this.shkBag(0)}>Распечатать</Button></div>
                    <ul>
                        {this.state.row.map((val, i) => (<li className="border" key={i}>{val.label}</li>))}
                    </ul></div>
            </div>
        </>);
    }
}
class TieBaggage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', content: '', spiner: '', orgList: [], org_id: '', baggage: [], bag_id: [], description: '', cell: '' }
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    orgChange = (event) => {
        this.setState({ org_id: event.value })
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ orgList: res.data });
        });
        axios.post(DOMEN_SERVER + LOGISTIC_LABEL_BAGGAGE, { email: this.state.email, quantity: 0 }).then(res => {
            this.setState({ baggage: res.data, spiner: '' });
        });
    }
    bagChange = (event) => {
        const arr = [];
        event.map((val, i) => (arr.push(val.value)));
        this.setState({ bag_id: arr });
    }
    connect = () => {
        if (this.state.org_id === '' || this.state.bag_id.length === 0) {
            alert('ошибка не указаны значения');
        } else {
            axios.post(DOMEN_SERVER + LOGISTIC_TIE_BAGGAGE, {
                email: this.state.email,
                bag_id: this.state.bag_id,
                organization_id: this.state.org_id,
                description: this.state.description,
                cell: this.state.cell
            }).then(res => {
                this.props.TieBaggage2();
            });

        }

    }
    render() {
        return (<>
            {this.state.spiner}
            <h1>Привязка ярлыков</h1>
            <Select className="basic-multi-select w-100 m-1" isMulti placeholder="Ярлыки" options={this.state.baggage} onChange={this.bagChange} />
            <Select className="w-100 m-1" placeholder="Организация" options={this.state.orgList} onChange={this.orgChange} />
            <Form>
                <div className="d-flex"><Form.Control className="m-1" size="sm" name="cell" value={this.state.cell} placeholder="Ячейка" onChange={this.inputChange}>
                </Form.Control>
                </div>
                <div className="d-flex"><Form.Control className="m-1" size="sm" name="description" value={this.state.description} placeholder="Примечание" onChange={this.inputChange}>
                </Form.Control>
                    <Button size="sm" className="m-1" variant="success" onClick={this.connect}>Сохранить</Button></div>
            </Form>
        </>);
    }
}

class AllBaggage extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'name', headerName: 'Ярлык', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'organization', headerName: 'организация', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'cell', headerName: 'Ячейка', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'description', headerName: 'Примечание', width: 120, headerAlign: 'center', align: 'center' },
        ];

        this.state = { email: '', content: '', spiner: '', col: col, rows: [], description: '', cell: '', rowSelectionModel: [] }
        this.handleChange = this.handleChange.bind(this);
    }
    CustomToolbar = (props) => {
        let save;
        if (this.state.rowSelectionModel.length > 0) {
            save = <Form>
                <div className="d-flex"><Form.Control className="m-1" size="sm" name="cell" placeholder="Ячейка" value={this.state.cell} onChange={this.handleChange}>
                </Form.Control><Form.Control className="m-1" size="sm" name="description" value={this.state.description} placeholder="Примечание" onChange={this.handleChange}>
                    </Form.Control></div>
                <div className="d-flex">
                    <Button size="sm" className="m-2" variant="success" onClick={this.handleUpdateClick}>Сохранить</Button> <Button size="sm" className="m-2" variant="danger" onClick={this.handleDeleteClick}>Удалить</Button></div>
            </Form>;

        }
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Короба_' + this.state.name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                <div>{save}</div>
            </GridToolbarContainer>
        );
    }
    selForeach = (newRowSelectionModel) => {
        this.setState({ rowSelectionModel: newRowSelectionModel });
    }

    handleUpdateClick = () => {
        axios.post(DOMEN_SERVER + LOGISTIC_UP_ALL_BAGGAGE, {
            email: this.state.email,
            bag_id: this.state.rowSelectionModel,
            description: this.state.description,
            cell: this.state.cell
        }).then(res => {
            this.setState({ cell: '', description: '' })
            this.connect();
        });
    }
    handleDeleteClick = () => {
        if (window.confirm("Вы действительно хотите удалить?")) {
            axios.post(DOMEN_SERVER + LOGISTIC_DELETE_BAGGAGE, { bag_id: this.state.rowSelectionModel }).then(res => {
                this.connect();
            });
        }
    }
    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email }, this.connect);
    }
    connect = () => {
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + LOGISTIC_ALL_BAGGAGE).then(res => {
            this.setState({ rows: res.data, spiner: '' });
        });
    }

    render() {
        return (<>
            {this.state.spiner}
            <h1>Багажи</h1>
            <Box
                width='100%'
            >
                <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                    checkboxSelection
                    disableRowSelectionOnClick
                    slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        this.selForeach(newRowSelectionModel);
                    }}
                    rowSelectionModel={this.state.rowSelectionModel}

                />
            </Box>
        </>);
    }
}

export class Bagage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', content: '', spiner: '' }
    }

    LabelBagage = () => {
        this.setState({ content: <LabelBagage /> });
    }
    TieBaggage = () => {
        this.setState({ content: <TieBaggage TieBaggage2={this.TieBaggage2} /> });
    }
    TieBaggage2 = () => {
        this.setState({ content: '' }, this.TieBaggage);
    }
    AllBaggage = () => {
        this.setState({ content: <AllBaggage /> });
    }
    render() {
        return (<>
            {this.state.spiner}
            <div >
                <Container>
                    <Row className="d-flex gap-4">
                        <Col><Button size="lg" variant="warning" onClick={this.LabelBagage}>Ярлык</Button></Col>
                        <Col><Button size="lg" variant="warning" onClick={this.TieBaggage}>Привязать</Button></Col>
                        <Col><Button size="lg" variant="warning" onClick={this.AllBaggage}>Багажи</Button></Col>
                    </Row>
                </Container>
                <Container >
                    <Row>
                        <Col>
                            {this.state.content}
                        </Col>
                    </Row>

                </Container>
            </div>
        </>);
    }
}