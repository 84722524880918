import React from "react";
import { Outlet } from 'react-router-dom';
import {Futer,NavBar } from './Template';
export class NotFound  extends React.Component {
    
    render() {
        return (
           <div>
            <h5 className="text-warning">404... Упс страница не найдена</h5>
           </div>
        );
    }
}
export class Hello extends React.Component{
constructor(props){
    super(props);
    this.state={name:''};
}
text=()=>{
   
    const welcome =<div><h5> <b><i>{this.state.name}</i></b>, вы находитесь в CRM-системе ELIDA WORKSPACE!</h5>
<p>Мы рады, что вы присоединились к нашей команде профессионалов и стали частью нашей дружной семьи.</p>
<p>Наша компания - это место, где каждый может реализовать свои идеи и достичь успеха в карьере. Мы предлагаем комфортные условия работы, обучение и развитие, а также поддержку и помощь в любых вопросах.</p>
<p>Мы ценим каждого нашего сотрудника и стремимся создать атмосферу доверия и уважения в коллективе. Мы верим в то, что только вместе мы можем достичь больших результатов и стать лучшими в своем деле.</p>
<span>Добро пожаловать на борт Elida и желаем вам успехов в работе и личной жизни!</span></div>;
const guest = <h5>CRM system - Elida company workspace!</h5>;
const blocked = <h1 className="text-danger">Вам ограничили доступ!</h1>;
if(localStorage.getItem('user')!==null){
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({name:user.name})
    if(user.level!==0){
        return welcome;
    }else{
         return blocked;
    }
}else{
    return guest;
}
}

    render(){
        return(<div className="col-11 col-sm-9 col-md-6 col-lg-5 col-xl-4 ">
            {this.text()}
        </div>);
    }
}
function home() {

    const page = (

        <div className="App"  >

            <header className="App-header">
                {NavBar()}
            </header>
            <div className="App-body">
                <Outlet />

            </div>
            {Futer()}
        </div>
    );

    return (page);
}
export const Home = home();