//----------DOMAINS----------

export const DOMEN_SERVER="https://swork.elida.company/api"
export const  DOMEN_SITE="https://work.elida.company"
//export const DOMEN_SERVER="http://192.168.1.7/api"
// export const DOMEN_SERVER="http://127.0.0.1:8000/api"
// export const  DOMEN_SITE="http://localhost:3000"

//----------API----------

export const  AUTH_REGISTER="/auth/register"
export const  AUTH_LOGIN="/auth/login"
export const  AUTH_LOGOUT="/auth/logout"
export const  AUTH_LOGOUT_ALL="/auth/logout-all-device"
export const  AUTH_NEWPASSWORD="/auth/newpassword"
export const  AUTH_LEVEL="/auth/level"
export const  AUTH_CREATE_RECOVERY="/auth/create-recovery"
export const  AUTH_RECOVERY_TOKEN="/auth/recovery-token"

export const  CABINET_MYSALARY="/cabinet/mysalary"
export const  CABINET_MY_SALARY_DATA="/cabinet/my_salary_data"
export const  CABINET_MYPROFILE="/cabinet/myprofile"
export const  CABINET_MYWORK="/cabinet/mywork"


export const  ADMIN_USERS="/admin/users"
export const  ADMIN_UPLEVEL="/admin/uplevel"
export const  ADMIN_CRETE_SERVICE="/admin/createservice"
export const  ADMIN_SERVICE="/admin/services"
export const  ADMIN_CATEGORY_LIST="/admin/categorylist"
export const  ADMIN_VALUTA="/admin/valuta"
export const  ADMIN_UPDATE_SERVICE="/admin/updateservice"
export const  ADMIN_CASH_ADD="/admin/cash_add"
export const  ADMIN_CASH_UP="/admin/cash_up"
export const  ADMIN_CASH="/admin/cash"

export const  MANEGMENT_CREATE_COUNTERPARTY="/managment/create-counterparty"
export const  MANEGMENT_UPDATE_COUNTERPARTY="/managment/update-counterparty"
export const  MANEGMENT_UPDATE_COUNTERPARTY_PHONE="/managment/update-counterparty-phone"
export const  MANEGMENT_COUNTERPARTIES="/managment/counterparties"
export const  MANEGMENT_COUNTERPARTY="/managment/counterparty"
export const  MANEGMENT_ORG_CREATE="/managment/createorganization"
export const  MANEGMENT_ORG_LIST="/managment/organizationslist"
export const  MANEGMENT_CREATE_SUBJECT="/managment/createsubject"
export const  MANEGMENT_UPDATE_SUBJECT="/managment/updatesubject"
export const  MANEGMENT_SUBJECTS="/managment/subjects"
export const  MANEGMENT_SUBJECTS_ORG="/managment/subjects_org"
export const  MANEGMENT_SERVICE_APP="/managment/service_app"
export const  MANEGMENT_SERVICE_LIST="/managment/service_list"
export const  MANEGMENT_CREATE_APP="/managment/create_application"
export const  MANEGMENT_APLICATIONS="/managment/aplications"
export const  MANEGMENT_UPDATE_APP_ST="/managment/update_app_status"
export const  MANEGMENT_APP_SUB="/managment/app_sub"
export const  MANEGMENT_UPDATE_APP="/managment/update_application"
export const  MANEGMENT_EXEL_RAZBIVKA="/managment/exelrazbivka"
export const  MANEGMENT_SERVICE_PRICE="/managment/service_price"
export const  MANEGMENT_MERCHANDIS_CREATE="/managment/merchandis_create"
export const  MANEGMENT_MERCHANDIS_ROW="/managment/merchandis_row"
export const  MANEGMENT_SMENA="/managment/smena"
export const  MANEGMENT_SMENA_INSERT="/managment/smena_insert"
export const  MANEGMENT_SMENA_TOP="/managment/smena_top"
export const  MANEGMENT_UPDATE_ORGANIZATION="/managment/update_organization"
export const  MANEGMENT_OPERATIONS_APPLICATION="/managment/operations_application"
export const  MANEGMENT_UPDATE_OPERATION_NUM="/managment/update_operation_num"
export const  MANEGMENT_PRINT_SHK_BOX="/managment/print_shk_box"


export const  TASK_CREATE_ARTICLE="/task/createarticle"
export const  TASK_UPDATE_ARTICLE="/task/updateArticle"
export const  TASK_ARTICLES="/task/articles"
export const  TASK_DELETE_ARTICLE="/task/delete_article"
export const  TASK_ORG_LIST="/task/orglist"
export const  TASK_ARTICLE="/task/article"
export const  TASK_SUB_LIST="/task/sublist"
export const  TASK_CREATE_BOX="/task/create_box"
export const  TASK_APP_ARTICLES="/task/app_articles"
export const  TASK_CREATE_OPTION="/task/create_option"
export const  TASK_DELETE_OPTION="/task/delete_option"
export const  TASK_OPERATIONS_BOX="/task/operations_box"
export const  TASK_DETAIL_BOX="/task/detal_box"
export const  TASK_DELETE_BOX="/task/delete_box"
export const  TASK_WB_IMPORT_ARTICLES="/task/wb_import_articles"
export const  TASK_SUBJECTS_COUNTRY="/task/subjects_country"
export const  TASK_ARTICLES_ALL_REQUEST="/task/articles_all__request"



export const  LOGISTIC_BOX_STATUS="/logistic/boxes_status"
export const  LOGISTIC_UP_BOX_STATUS="/logistic/up_box_status"
export const  LOGISTIC_UP_BOX_CELL="/logistic/up_box_cell"
export const  LOGISTIC_RAZBIVKA_BOXES="/logistic/razbivka_boxes"
export const  LOGISTIC_SERVICE_TRANSPORT="/logistic/service_transport"
export const  LOGISTIC_SERVICE_TRANSPORT_ROW="/logistic/service_transport_row"
export const  LOGISTIC_LABEL_BAGGAGE="/logistic/labelbaggage"
export const  LOGISTIC_TIE_BAGGAGE="/logistic/tiebaggage"
export const  LOGISTIC_ALL_BAGGAGE="/logistic/allbaggage"
export const  LOGISTIC_UP_ALL_BAGGAGE="/logistic/upallbaggage"
export const  LOGISTIC_DELETE_BAGGAGE="/logistic/deletebaggage"
export const  LOGISTIC_SEARCH_BOX="/logistic/searchbox"
export const  LOGISTIC_STATUS_BOX="/logistic/statusbox"



export const  ACCOUNTING_ADD_ENTRIES="/accounting/add_entries"
export const  ACCOUNTING_ENTRIES_LIST="/accounting/entries_list"
export const  ACCOUNTING_ENTRIES_UPDATE="/accounting/entries_update"
export const  ACCOUNTING_ENTRIES_DELETE="/accounting/entries_delete"
export const  ACCOUNTING_APP_LIST_END="/accounting/app_list_end"
export const  ACCOUNTING_APP_REPORTS="/accounting/app_reports"
export const  ACCOUNTING_APP_STATUS_END="/accounting/app_status_end"
export const  ACCOUNTING_COUNTER_ENTRIES="/accounting/counter_enties"
export const  ACCOUNTING_CREATE_BILL="/accounting/create_bill"
export const  ACCOUNTING_INVOICE_VIEW="/accounting/invoice_view"
export const  ACCOUNTING_CHECK_VIEW="/accounting/check_view"
export const  ACCOUNTING_COUN_JOURNAL="/accounting/coun_journal"
export const  ACCOUNTING_DELETE_INVOICE="/accounting/delete_invoice"
export const  ACCOUNTING_COUNTERPARTIES="/accounting/counterparties"
export const  ACCOUNTING_ITEMS="/accounting/items"
export const  ACCOUNTING_ADD_ITEM="/accounting/add_item"
export const  ACCOUNTING_GROUP_ITEMS="/accounting/group_items"
export const  ACCOUNTING_ACCEPT_PAYMENT="/accounting/accept_payment"
export const  ACCOUNTING_PERSONAL="/accounting/personal"
export const  ACCOUNTING_PAY_EXPENSE="/accounting/pay_expense"
export const  ACCOUNTING_LOCALE_TRANSFER="/accounting/locale_transfer"
export const  ACCOUNTING_ADD_SALARY="/accounting/add_salary"
export const  ACCOUNTING_CASHBOX_TOP="/accounting/cashbox_top"
export const  ACCOUNTING_SALARY_TOP="/accounting/salary_top"
export const  ACCOUNTING_SALARY_CALCULATION="/accounting/salary_calculation"
export const  ACCOUNTING_PERSONAL_LIST_ID="/accounting/personal_list_id"
export const  ACCOUNTING_PERSONAL_LIST="/accounting/personal_list"
export const  ACCOUNTING_SERVICE_AGR="/accounting/servise_agr"
export const  ACCOUNTING_NEW_AGR="/accounting/new_agr"
export const  ACCOUNTING_AGR_ROW_REQUEST="/accounting/agr_row_request"
export const  ACCOUNTING_AGR_DELETE="/accounting/agr_delete"
export const  ACCOUNTING_SERVICE_PRICE="/accounting/service_price"
export const  ACCOUNTING_SERVICE_ACTUAL_PRICE="/accounting/servise_actual_price"
export const  ACCOUNTING_CASH_SELECT="/accounting/cash_select"
export const  ACCOUNTING_TOTAL_REPORT="/accounting/totall_report"
export const  ACCOUNTING_PASSIVIES="/accounting/passivies"
export const  ACCOUNTING_PASSIVE_ADD="/accounting/passive_add"
export const  ACCOUNTING_PASSIVE_DELETE="/accounting/passive_delete"
export const  ACCOUNTING_CREDIT_INVOICE="/accounting/credit_invoice"
export const  ACCOUNTING_SHAREHOLDERS="/accounting/shareholders"
export const  ACCOUNTING_DIVIDEND="/accounting/dividend"
export const  ACCOUNTING_DIVIDENDS="/accounting/dividends"
export const  ACCOUNTING_DIVIDEND_USER="/accounting/dividend_user"


