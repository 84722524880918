import React from "react";
import { DOMEN_SERVER, ADMIN_USERS, ADMIN_UPLEVEL, ADMIN_CRETE_SERVICE, ADMIN_SERVICE, ADMIN_CATEGORY_LIST, ADMIN_VALUTA, ADMIN_UPDATE_SERVICE, ADMIN_CASH, ADMIN_CASH_ADD } from "../Config/Base";
import Select from "react-select";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { DataGrid, GridToolbarContainer, GridToolbarExport, ruRU, GridRowModes, GridActionsCellItem, gridDateTimeFormatter, } from "@mui/x-data-grid";
import { ItemsModal, Spiner } from "./Accounting";
import { SessionEndend } from "./Auth";
import { Public } from "@mui/icons-material";
import { DateTimePicker } from "@mantine/dates";
//Роли
export class Users extends React.Component {
    constructor(props) {
        super(props);

        const col = [{ field: 'id', headerName: 'ID', width: 50, headerAlign: 'center', },
        { field: 'name', headerName: 'Имя', width: 150, headerAlign: 'center', },
        { field: 'email', headerName: 'Email', width: 200, headerAlign: 'center', },
        { field: 'level', headerName: 'Доступ', width: 65, Align: 'right', headerAlign: 'center', type: 'singleSelect', editable: true, valueOptions: [0, 1, 2, 3, 4, 5] },
        { field: 'grafik', headerName: 'График', width: 140, align: 'center', headerAlign: 'center', editable: true },
        { field: 'oklad', headerName: 'Оклад', width: 65, headerAlign: 'center', Align: 'right', editable: true, },
        { field: 'bonus', headerName: 'Бонус', width: 65, headerAlign: 'center', Align: 'right', editable: true, },
        { field: 'created_at', headerName: 'Регистрация', width: 100, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'updated_at', headerName: 'Активность', width: 200, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'dateTime', },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={this.handleEditClick(id)}
                        color="inherit"
                    />,

                ];
            },

        },
        ];
        this.state = { rows: [], col: col, email: '', rowModesModel: {}, setRowModesModel: {} }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + ADMIN_USERS).then(res => {
            this.setState({ rows: res.data });
        })
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        axios.post(DOMEN_SERVER + ADMIN_UPLEVEL, { email: this.state.email, id: updatedRow.id, level: updatedRow.level, oklad: updatedRow.oklad, bonus: updatedRow.bonus, grafik: updatedRow.grafik }).then(res => {
            this.setState({ rows: res.data });
        });
        return updatedRow;
    }
    handleEditClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    };
    handleSaveClick = (id) => () => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => () => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }

    };
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        this.setState({ setRowModesModel: newRowModesModel });
    };
    render() {
        return (<>

            <div className="level_info col-10">
                <b>Уровни доступа по отделам</b><br />
                <cite><b>0.</b>Профиль <b> 1.</b>Производство, Профиль <b> 2. </b>Склад, Производство,Профиль <b>3.</b>Менеджмент, Склад, Производство, Профиль <b> 4.</b>Бухгалтерия, Менеджмент, Склад, Производство, Профиль <b>
                    5.</b>Админка, Бухгалтерия, Менеджмент,Склад, Производство, Профиль</cite>
            </div>
            <h6>Список сотрудников</h6>
            <Box sx={{ width: '90%', }}>
                <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    editMode="row"
                    processRowUpdate={this.processRowUpdate}
                    onProcessRowUpdateError={this.handleProcessRowUpdateError}
                    rowModesModel={this.state.rowModesModel}
                    onRowModesModelChange={this.handleRowModesModelChange}
                />
            </Box>;
        </>);
    }
}



export class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryList: [], showNew: false, showEdit: false, valuta: [], modal: '', services: [], email: '', hidden: 'hidden', category_index: 0, category_id: 1, id: '',
            name: '', rate: 0, price: 0, valuta_id: 1, valuta_index: 0, description: ''
        };
        this.inputChange = this.inputChange.bind(this);
    }

    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, hidden: '' })
        axios.post(DOMEN_SERVER + ADMIN_CATEGORY_LIST).then(res => {
            this.setState({ categoryList: res.data });
            axios.post(DOMEN_SERVER + ADMIN_VALUTA).then(val => {
                this.setState({ valuta: val.data, hidden: 'hidden' });
            });
        });
    }

    categoryChange = (event) => {
        this.setState({ category_id: event.value });
        this.servicies(event.value);
    }
    servicies = (category_id) => {
        axios.post(DOMEN_SERVER + ADMIN_SERVICE, { category_id: category_id }).then(res => {
            this.setState({ services: res.data });
        });
    }
    valutaChange = (event) => {
        this.setState({ valuta_id: event.value });
    }
    categoryChangeEdit = (event) => {
        this.setState({ category_id: event.value });
    }
    handleShowEdit = (event) => {
        this.state.categoryList.map((val, index) => {
            if (val.value === this.state.category_id) {
                this.setState({ category_index: index });
            }
        });
        this.state.valuta.map((val, index) => {
            if (val.value === this.state.valuta_id) {
                this.setState({ valuta_index: index });
            }
        });
        this.state.services.map(service => {
            if (Number(service.id) === Number(event.target.value)) {
                this.state.valuta.map((val, index) => {
                    if (val.value === service.valuta_id) {
                        this.setState({ valuta_index: index });
                    }
                });
                this.setState({ id: service.id, name: service.name, rate: service.rate, price: service.price, valuta_id: service.valuta_id, description: service.description });
            }
        });
        this.setState({ showEdit: true });
    }
    handleShowNew = () => {
        this.state.categoryList.map((val, index) => {
            if (val.value === this.state.category_id) {
                this.setState({ category_index: index });
            }
        });
        this.state.valuta.map((val, index) => {
            if (val.value === this.state.valuta_id) {
                this.setState({ valuta_index: index });
            }
        });
        this.setState({ showNew: true });
    }

    handleCloseEdit = () => {
        this.setState({ showEdit: false, id: '', name: '', rate: 0, price: 0, valuta_id: 1, description: '' });
    }
    handleCloseNew = () => {
        this.setState({ showNew: false, name: '', rate: 0, price: 0, valuta_id: 1, description: '' })
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    saveEdit = () => {
        axios.post(DOMEN_SERVER + ADMIN_UPDATE_SERVICE, { email: this.state.email, category_id: this.state.category_id, id: this.state.id, name: this.state.name, rate: this.state.rate, price: this.state.price, valuta_id: this.state.valuta_id, description: this.state.description }).then(res => {
            this.servicies(this.state.category_id,);
            alert(res.data.message);
        }).catch(() => {
            alert('Не удолось сохранить');
        });
    }
    saveNew = () => {
        axios.post(DOMEN_SERVER + ADMIN_CRETE_SERVICE, { email: this.state.email, category_id: this.state.category_id, name: this.state.name, rate: this.state.rate, price: this.state.price, valuta_id: this.state.valuta_id, description: this.state.description }).then(res => {
            this.servicies(this.state.category_id);
            this.setState({ name: '', price: 0, rate: 0, description: '' });
            alert(res.data.message);
        }).catch(() => {
            alert('Не удолось сохранить');
        });
    }
    itemsModal = () => {
        this.setState({ modal: <ItemsModal closeModal={this.closeModal} /> });
    }
    closeModal = () => {
        this.setState({ modal: '' });
    }
    render() {

        return (<>{this.state.modal}
            < div className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                <div className="overlay" hidden={this.state.hidden}>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow text-primary" role="status" ></div>
                        <div className="spinner-grow text-primary" role="status" ></div>
                        <div className="spinner-grow text-primary" role="status" ></div>
                    </div>
                    <span className="m-2"> Loading...</span>
                </div>
                <h1>Сервисы</h1>
                <div className="d-flex">
                    <Select className="w-100  m-1"
                        onChange={this.categoryChange}
                        placeholder="Категория"
                        options={this.state.categoryList} />
                    <Button onClick={this.handleShowNew} className="ms-2 mb-2 mt-1" size="sm" variant="secondary">Добавить</Button>
                    <Button className="ms-2 mb-2 mt-1" size="sm" variant="secondary" onClick={this.itemsModal}>Элементы</Button>
                </div>
                <div>
                    <Table>
                        <thead><tr><th>Код</th><th>Наименование</th><th>Ставка</th><th>Цена</th><th>Валюта</th><th>Описание</th><th>#</th></tr></thead>
                        <tbody>
                            {this.state.services.map(service => (
                                <tr><td>{service.id}</td><td>{service.name}</td><td>{service.rate}</td><td>{service.price}</td><td>{service.code}</td><td>{service.description}</td><td><Button variant="secondary" value={service.id} onClick={this.handleShowEdit}>&#9998;</Button></td></tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Modal show={this.state.showEdit} onHide={this.handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Редактировать сервис</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table><tbody>
                            <tr><td>Код:</td><td><b>{this.state.id}</b></td></tr>
                            <tr><td>Категория:</td><td><Select onChange={this.categoryChangeEdit} defaultValue={this.state.categoryList[this.state.category_index]} options={this.state.categoryList} /> </td></tr>
                            <tr><td>Наименование:</td><td><input type="text" className="w-100" value={this.state.name} name="name" placeholder="Маркировка товара" onChange={this.inputChange} /></td></tr>
                            <tr><td>Ставка:</td><td><input type="number" value={this.state.rate} name="rate" onChange={this.inputChange} /></td></tr>
                            <tr><td>Цена:</td><td><input type="number" value={this.state.price} name="price" onChange={this.inputChange} /></td></tr>
                            <tr><td>Валюта:</td><td><Select onChange={this.valutaChange} defaultValue={this.state.valuta[this.state.valuta_index]} options={this.state.valuta} /> </td> </tr>
                            <tr><td>Описание:</td><td><input type="text" className="w-100" value={this.state.description} name="description" placeholder="Описание сервиса" onChange={this.inputChange} /></td></tr>

                        </tbody></Table>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseEdit}>Закрыть</Button>
                            <Button variant="primary" onClick={this.saveEdit}>Сохранить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showNew} onHide={this.handleCloseNew}>
                    <Modal.Header closeButton>
                        <Modal.Title>Новый сервис</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table><tbody>
                            <tr><td>Категория:</td><td><Select onChange={this.categoryChangeEdit} defaultValue={this.state.categoryList[this.state.category_index]} options={this.state.categoryList} /> </td></tr>
                            <tr><td>Наименование:</td><td><input type="text" className="w-100" value={this.state.name} name="name" placeholder="Маркировка товара" onChange={this.inputChange} /></td></tr>
                            <tr><td>Ставка:</td><td><input type="number" value={this.state.rate} name="rate" onChange={this.inputChange} /></td></tr>
                            <tr><td>Цена:</td><td><input type="number" value={this.state.price} name="price" onChange={this.inputChange} /></td></tr>
                            <tr><td>Валюта:</td><td><Select onChange={this.valutaChange} defaultValue={this.state.valuta[this.state.valuta_index]} options={this.state.valuta} /> </td></tr>
                            <tr><td>Описание:</td><td><input type="text" className="w-100" value={this.state.description} name="description" placeholder="Описание сервиса" onChange={this.inputChange} /></td></tr>
                        </tbody></Table>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleCloseNew}>Закрыть</Button>
                            <Button variant="primary" onClick={this.saveNew}>Сохранить</Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </div>
        </>);
    }
}
export class Cash extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', id: '', name: '', number: '', rows: [], spiner: '', }
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + ADMIN_CASH,).then(res => {
            this.setState({ rows: res.data, spiner: '' });

        });
    }
    cashAdd = () => {
        this.setState({ spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + ADMIN_CASH_ADD, { name: this.state.name, number: this.state.number }).then(res => {
            this.setState({ rows: res.data, spiner: '' });
        });
    }
    render() {
        return (<>
            {this.state.spiner}
            <div>
                <h3>Счета(касса)</h3>
                <div className="d-flex">
                    <input type="text" id="name" name="name" value={this.state.name} onChange={this.inputChange} placeholder="Название" className="m-1" />
                    <input type="text" id="number" name="number" value={this.state.number} onChange={this.inputChange} className="m-1" />
                    <Button variant="success" className="m-1" onClick={this.cashAdd}>Добавить</Button>
                </div>
                <table><thead>
                    <tr><th>Название</th><th>Номер</th></tr>
                </thead>
                    <tbody>
                        {this.state.rows.map((val, i) => (<tr key={i}><td>{val.name}</td><td>{val.number}</td></tr>))}
                    </tbody>
                </table>
            </div>

        </>);
    }
}